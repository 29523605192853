import { useEffect, useState } from 'react'
import CurrentActiveUsers from './components/CurrentActiveUsers'
import LatestUsers from './components/LatestUsers'
import MonthlyEarning from './components/MonthlyEarning'
import { getApiHelper } from 'api/ApiHelper'
import { API } from 'api/ApiConfig'
import { toast } from 'react-toastify'
import DeviceType from './components/DeviceType'
import Genders from './components/Genders'
import UserRoles from './components/UserRoles'

const DashboardPage = () => {
  const [dashboard, setDashboard] = useState<any>()
  const getData = () => {
    getApiHelper(
      API.dashboard,
      (data) => {
        setDashboard(data)
        console.log(data)
      },
      (err) => {
        toast.error(err?.toString())
      }
    )
  }

  const genderFromIndex = (index: any) => {
    switch (index) {
      case 0:
        return 'FEMALE'
      case 1:
        return 'MALE'
      default:
        return 'MALE'
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div className="px-5">
      <div className="flex gap-[20px] items-stretch">
        <MonthlyEarning data={dashboard?.purchases} />
        <CurrentActiveUsers data={dashboard?.recentlyActive ?? '-'} />
        <DeviceType data={dashboard?.deviceCount} />
      </div>
      <div className="mt-[20px] flex gap-[20px] items-stretch">
        <Genders data={dashboard?.genderCount} />
        <UserRoles data={dashboard?.roleCount} />
      </div>
      <div className="mt-[20px]">
        <LatestUsers />
      </div>
    </div>
  )
}

export default DashboardPage
