interface PageHeaderProps {
  title: string
  hasAdd?: boolean
  actionTitle?: string
  action?: () => void
}
const PageHeader = ({ title, hasAdd = false, action, actionTitle = 'Add' }: PageHeaderProps) => {
  return (
    <div className="flex items-center justify-between">
      <h1 className="font-bold text-[20px]">{title}</h1>
      {hasAdd && (
        <button
          onClick={action}
          className="text-sm text-white h-[40px] rounded-[10px] px-1 min-w-[100px]"
          style={{
            background: 'linear-gradient(90.5deg, #5BC0A1 -21.2%, #94CA65 52.32%, #B1D147 133.52%)'
          }}>
          {actionTitle}
        </button>
      )}
    </div>
  )
}

export default PageHeader
