import { API } from 'api/ApiConfig'
import { getApiHelper, postApiHelper, uploadImageApiHelper } from 'api/ApiHelper'
import InnerPageHeader from 'components/header/InnerPageHeader'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

const EditTrophiesPage = () => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [type, setType] = useState('workout')
  const [completed, setCompleted] = useState('')
  const [image, setImage] = useState('')
  const [imageFile, setImageFile] = useState<any>(undefined)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const fileRef = useRef<any>()

  const handleFileChange = (e: any) => {
    setImageFile(e.target.files[0])
  }

  const createTrophy = async () => {
    if (!name || !description || !completed) {
      toast('Please fill all the fields')
      return
    }
    setLoading(true)
    let mImage = image
    if (imageFile !== undefined) {
      mImage = await uploadImageApiHelper(imageFile)
    }
    postApiHelper(
      API.saveTrophy,
      {
        name,
        description,
        type,
        threshold: completed,
        mImage,
        id: location.pathname.split('/')[2]
      },
      (data) => {
        toast(data)
        setLoading(false)
        navigate(-1)
      },
      (err) => {
        console.log(err)
        setLoading(false)
      }
    )
  }

  const getTrophy = () => {
    const id = location.pathname.split('/')[2]

    getApiHelper(
      `${API.getTrophy}${id}`,
      (data) => {
        setName(data?.name ?? '')
        setDescription(data?.description ?? '')
        setType(data?.type ?? 'workout')
        setCompleted(data?.threshold ?? '')
        setImage(data?.image)
      },
      (err) => {
        toast.error(err?.toString())
      }
    )
  }

  useEffect(() => {
    getTrophy()
  }, [])

  return (
    <div className="px-5">
      <InnerPageHeader loading={loading} hasAction action={createTrophy} title="Edit Trophy" />
      <div className="flex mt-[10px] gap-[10px] items-end">
        {imageFile !== undefined ? (
          <img
            src={imageFile ? URL.createObjectURL(imageFile) : ''}
            className="h-[100px] object-contain w-[100px] bg-[#8080801A]"
            alt=""
          />
        ) : (
          <img src={image} className="h-[100px] object-contain w-[100px] bg-[#8080801A]" alt="" />
        )}

        <button
          onClick={() => {
            fileRef.current.click()
          }}
          className="text-white p-2 text-sm rounded-[50px]"
          style={{
            background: 'linear-gradient(90.5deg, #5BC0A1 -21.2%, #94CA65 52.32%, #B1D147 133.52%)'
          }}>
          Update Image
        </button>
        <input
          ref={fileRef}
          className="invisible w-[10px]"
          type="file"
          onChange={handleFileChange}
        />
      </div>
      <div className="mt-5">
        <p className="font-bold text-sm mb-1">Name</p>
        <input
          value={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
          className="bg-[#8080800D] border border-[#8080802A] rounded-md h-[40px] w-full outline-none p-1"
          placeholder="Enter Name"
        />
      </div>
      <div className="mt-5">
        <p className="font-bold text-sm mb-1">Description</p>
        <input
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          type="text"
          className="bg-[#8080800D] border border-[#8080802A] rounded-md h-[40px] w-full outline-none p-1"
          placeholder="Enter Description"
        />
      </div>
      <div className="mt-5">
        <p className="font-bold text-sm mb-1">User Type</p>
        <select
          onChange={(e) => setType(e.target.value)}
          value={type}
          className="bg-[#8080800D] border border-[#8080802A] rounded-md h-[40px] outline-none p-1"
          name=""
          id="">
          <option value="workout">Workout</option>
          <option value="challenges">Challenges</option>
          <option value="steps">Steps</option>
        </select>
      </div>
      <div className="mt-5">
        <p className="font-bold text-sm mb-1">How many {type} completed</p>
        <input
          value={completed}
          onChange={(e) => setCompleted(e.target.value)}
          type="number"
          className="bg-[#8080800D] border border-[#8080802A] rounded-md h-[40px] w-full outline-none p-1"
          placeholder="Enter Description"
        />
      </div>
    </div>
  )
}

export default EditTrophiesPage
