import ListModal from 'components/modal/ListModal'
import { AnimatePresence } from 'framer-motion'
import { useMemo, useState } from 'react'
import { GrUserWorker } from 'react-icons/gr'

interface ItemProps {
  data: any
}
const UserRoles = ({ data }: ItemProps) => {
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const total = useMemo(() => {
    if (data) {
      let total = 0
      total = parseInt(data.admin) + parseInt(data.regular) + parseInt(data.premium)
      return total
    }
    return 1
  }, [data])
  const regularPercent = useMemo(() => {
    if (data) {
      // const total = parseInt(data.admin) + parseInt(data.regular) + parseInt(data.premium)
      return parseInt(data.regular) / total
    }
    return 1
  }, [data])

  const premiumPercent = useMemo(() => {
    if (data) {
      // const total = parseInt(data.admin) + parseInt(data.regular) + parseInt(data.premium)
      return parseInt(data.premium) / total
    }
    return 1
  }, [data])
  const adminPercent = useMemo(() => {
    if (data) {
      // const total = parseInt(data.admin) + parseInt(data.regular) + parseInt(data.premium)
      return parseInt(data.admin) / total
    }
    return 1
  }, [data])

  const subAdminPercent = useMemo(() => {
    if (data) {
      // const total = parseInt(data.admin) + parseInt(data.regular) + parseInt(data.premium)
      return parseInt(data.subadmin) / total
    }
    return 1
  }, [data])

  const handleShowData = (type: string) => {
    setShowModal(true)
    setModalTitle(`${type}`)
  }

  return (
    <div
      className="flex-1 rounded-lg p-5"
      style={{
        boxShadow: '0px 0px 100px #8080804D'
      }}>
      <AnimatePresence initial={false}>
        {showModal && <ListModal title={modalTitle} handleCloseModal={() => setShowModal(false)} />}
      </AnimatePresence>
      <div className="flex items-center justify-between">
        <div className="cursor-pointer" onClick={() => handleShowData('premium')}>
          <h1 className="text-[25px]">{data?.premium}</h1>
          <p>Premium</p>
        </div>
        <div className="cursor-pointer" onClick={() => handleShowData('regular')}>
          <h1 className="text-[25px]">{data?.regular}</h1>
          <p>Regular</p>
        </div>
        <div className="cursor-pointer" onClick={() => handleShowData('admin')}>
          <h1 className="text-[25px]">{data?.admin}</h1>
          <p>Admin</p>
        </div>
        <div className="cursor-pointer" onClick={() => handleShowData('admin')}>
          <h1 className="text-[25px]">{data?.subadmin}</h1>
          <p>Sub Admin</p>
        </div>
        <GrUserWorker size={34} color="#21C55D" />
      </div>

      <div className="flex-1 mt-5">
        <div className="flex items-end gap-[10px]">
          <div className="flex flex-col items-center justify-center">
            <div
              onClick={() => handleShowData('premium')}
              style={{
                height: 70 * premiumPercent
              }}
              className={`rounded-md w-[25px] hover:opacity-50 cursor-pointer bg-red-500`}
            />
            <p className="text-sm">Premium</p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div
              onClick={() => handleShowData('regular')}
              style={{
                height: 70 * regularPercent
              }}
              className={`rounded-md w-[25px] hover:opacity-50 cursor-pointer bg-green-500`}
            />
            <p className="text-sm">Regular</p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div
              onClick={() => handleShowData('admin')}
              style={{
                height: 70 * adminPercent
              }}
              className="rounded-md w-[25px] hover:opacity-50 cursor-pointer bg-purple-500"
            />
            <p className="text-sm">Admin</p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div
              onClick={() => handleShowData('subadmin')}
              style={{
                height: 70 * subAdminPercent
              }}
              className="rounded-md w-[25px] hover:opacity-50 cursor-pointer bg-purple-500"
            />
            <p className="text-sm">Subadmin</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserRoles
