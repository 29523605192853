import { Link } from 'react-router-dom'
import ReportType from './ReportType'

interface ItemProps {
  report: any
}
const ReportItem = ({ report }: ItemProps) => {
  return (
    <div className="flex items-center px-2 gap-[10px] py-3">
      <p className="flex-1 text-sm">{report?.reason}</p>
      <p className="text-sm w-[30%]">{report?.description}</p>
      <Link to={`/users/${report?.reporter?.id}`} className="flex-1">
        <p className="text-sm text-[#94CA65] font-semibold">@{report?.reporter?.username}</p>
      </Link>
      <Link
        to={`/users/${report?.reported?.id ?? report?.reportedPost?.author?.id}`}
        className="flex-1">
        <p className="text-sm text-[#94CA65] font-semibold">
          @{report?.reported?.username ?? report?.reportedPost?.author?.username}
        </p>
      </Link>
      <div className="flex-1">
        <ReportType type={report?.reportedPost ? 'Post' : 'User'} />
      </div>
      <Link to={`/report/${report?.id}`} className="flex-1">
        <p className="text-sm text-[#94CA65] font-semibold">Take Action</p>
      </Link>
    </div>
  )
}

export default ReportItem
