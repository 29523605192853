import { API } from 'api/ApiConfig'
import { deleteApiHelper } from 'api/ApiHelper'
import DeleteModal from 'components/modal/DeleteModal'
import { AnimatePresence } from 'framer-motion'
import moment from 'moment'
import { useState } from 'react'
import { AiTwotoneDelete, AiTwotoneEdit } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

interface Itemprops {
  refresh: () => void
  trophy: any
}
const TrophyItem = ({ refresh, trophy }: Itemprops) => {
  const [showModal, setShowModal] = useState(false)
  const deleteTrophy = () => {
    deleteApiHelper(
      `${API.deleteTrophy}${trophy.id}`,
      () => {
        close()
        toast('Tip deleted')
        refresh()
      },
      (err) => {
        toast.error(err.toString())
      }
    )
  }

  const open = () => {
    setShowModal(true)
  }

  const close = () => {
    setShowModal(false)
  }

  return (
    <div className="flex items-center gap-[10px] px-2 py-3">
      <AnimatePresence initial={false}>
        {showModal && (
          <DeleteModal
            description="You are about to delete this tip, you cannot reverse this action"
            handleDelete={deleteTrophy}
            handleCloseModal={close}
          />
        )}
      </AnimatePresence>
      <p className="w-[20%] text-sm">{trophy?.name}</p>
      <p className="w-[30%] text-sm">{trophy?.description}</p>
      <p className="w-[15%] text-sm">
        {trophy?.threshold}({trophy?.type})
      </p>
      <p className="flex-1 text-sm">{moment(trophy?.createdAt).format('DD-MMM-YYYY')}</p>
      <div className="flex items-center gap-[10px] flex-1">
        <Link to={`/trophies/${trophy.id}`}>
          <AiTwotoneEdit size={20} cursor="pointer" color="#94CA65" />
        </Link>
        <AiTwotoneDelete size={20} cursor="pointer" onClick={open} color="red" />
      </div>
    </div>
  )
}

export default TrophyItem
