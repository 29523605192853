import moment from 'moment'
import { AiTwotoneDelete } from 'react-icons/ai'
import UserType from './UserType'
import { ImBlocked } from 'react-icons/im'
import { CgUnblock } from 'react-icons/cg'
import { AnimatePresence } from 'framer-motion'
import DeleteModal from 'components/modal/DeleteModal'
import { useState } from 'react'
import { deleteApiHelper, postApiHelper } from 'api/ApiHelper'
import { API } from 'api/ApiConfig'
import { toast } from 'react-toastify'
import TakeActionModal from './TakeActionModal'
import { Link } from 'react-router-dom'
import EnterPasswordModal from 'components/modal/EnterPasswordModal'

interface ItemProps {
  user?: any
  refresh?: () => void
}
const UserItem = ({ user, refresh = () => {} }: ItemProps) => {
  const [showDelete, setShowDelete] = useState(false)
  const [showBlockModal, setShowBlockModal] = useState(false)
  const [showUnblockModal, setShowUnblockModal] = useState(false)

  const blockUser = (password: string) => {
    postApiHelper(
      API.takeAction,
      { action: 'Block', userId: user.id, rawPassword: password },
      (data) => {
        setShowBlockModal(false)
        toast(data)
        refresh()
      },
      (err) => {
        toast.error(err.toString())
      }
    ).catch((err) => {
      console.log(err)
    })
  }

  const deleteUser = (password: string) => {
    deleteApiHelper(
      `${API.deleteUser}${user.id}?rawPassword=${password}`,
      () => {
        setShowDelete(false)
        toast('User has been removed from the platform')
        refresh()
      },
      (err) => {
        toast.error(err.toString())
      }
    ).catch((err) => console.log(err))
  }

  const unblockUser = (password: string) => {
    postApiHelper(
      API.takeAction,
      { action: 'Unblock', userId: user.id, rawPassword: password },
      (data) => {
        setShowUnblockModal(false)
        toast(data)
        refresh()
      },
      (err) => {
        toast.error(err.toString())
      }
    ).catch((err) => {
      console.log(err)
    })
  }

  return (
    <div className="px-2 py-3 flex items-center gap-[10px]">
      <AnimatePresence>
        {showUnblockModal && (
          <EnterPasswordModal
            title="Unblock User"
            description="Are you sure you want to unblock this user?"
            handleCloseModal={() => setShowUnblockModal(false)}
            handleConfirm={unblockUser}
          />
        )}
        {showBlockModal && (
          <EnterPasswordModal
            title="Block User"
            description="Are you sure you want to block this user?"
            handleCloseModal={() => setShowBlockModal(false)}
            handleConfirm={blockUser}
          />
        )}
        {showDelete && (
          <EnterPasswordModal
            title="Delete User"
            description="You are about to delete this user, this action is not reversible!!"
            handleCloseModal={() => setShowDelete(false)}
            handleConfirm={deleteUser}
          />
        )}
      </AnimatePresence>
      <Link className="w-[12%]" to={`/users/${user.id}`}>
        <p className="hover:text-[#94CA65] text-sm">{user?.fullName ?? '-'}</p>
      </Link>
      <p className="text-sm w-[12%]">{user?.username}</p>
      <p className="text-sm w-[22%]">{user?.email}</p>
      <UserType type={user.role} />
      <p className="text-sm w-[8%]">{user?.lastDevice?.toLowerCase() ?? 'ANDROID'}</p>
      <p className="text-sm w-[12%]">{moment(user.createdAt).format('DD-MMM-YYYY')}</p>
      <p className="text-sm w-[12%]">{moment(user.lastActive).format('DD-MMM-YYYY')}</p>
      <div className="flex items-center gap-[10px] w-[12%]">
        <AiTwotoneDelete
          title="Delete User"
          size={20}
          cursor="pointer"
          onClick={() => setShowDelete(true)}
          color="red"
        />
        {user.banned ? (
          <CgUnblock
            title="Unblock User"
            size={22}
            color="#94CA65"
            cursor="pointer"
            onClick={() => setShowUnblockModal(true)}
          />
        ) : (
          <ImBlocked
            title="Block User"
            size={18}
            color="red"
            cursor="pointer"
            onClick={() => setShowBlockModal(true)}
          />
        )}
      </div>
    </div>
  )
}
export default UserItem
