import { API } from 'api/ApiConfig'
import { deleteApiHelper, postApiHelper } from 'api/ApiHelper'
import DeleteModal from 'components/modal/DeleteModal'
import { AnimatePresence } from 'framer-motion'
import TakeActionModal from 'pages/users/components/TakeActionModal'
import { useState } from 'react'
import { AiTwotoneCopy, AiTwotoneDelete } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { RxDragHandleHorizontal } from 'react-icons/rx'

interface ItemProps {
  day?: any
  refresh: () => void
}
const DayItem = ({ day, refresh }: ItemProps) => {
  const [showDelete, setShowDelete] = useState(false)
  const [showClone, setShowClone] = useState(false)
  const deleteDay = () => {
    deleteApiHelper(
      `${API.deleteWorkoutPlanDay}${day.id}`,
      () => {
        toast('Deleted!')
        refresh()
      },
      (err) => {
        toast.error(err.toString())
      }
    )
  }

  const cloneSession = () => {
    postApiHelper(
      `${API.cloneWorkoutPlanDay}${day.id}`,
      {},
      () => {
        toast('Session cloned!')
        setShowClone(false)
        refresh()
      },
      (err) => {
        toast.error(err.toString())
      }
    )
  }

  return (
    <div className="py-3 mt-2 border-b border-[#8080802E] flex items-center w-full justify-between">
      <AnimatePresence>
        {showClone && (
          <TakeActionModal
            title="Clone Session"
            description="Clone this session and all its exercises"
            handleCloseModal={() => setShowClone(false)}
            action={cloneSession}
          />
        )}
        {showDelete && (
          <DeleteModal
            handleCloseModal={() => setShowDelete(false)}
            handleDelete={deleteDay}
            description="Delete this daily exercises from the workout plan?"
          />
        )}
      </AnimatePresence>
      <div className="flex items-center gap-3">
        <RxDragHandleHorizontal />
        <Link to={`/workout-plans/${location.pathname.split('/')[3]}/day/${day.id}`}>
          <p className="hover:text-[#94CA65] text-sm">{day.title}</p>
        </Link>
      </div>

      <div className="flex items-center gap-2">
        <AiTwotoneCopy
          title="Clone Session"
          size={20}
          cursor="pointer"
          onClick={() => setShowClone(true)}
          color="#94CA65"
        />
        <AiTwotoneDelete
          title="Delete User"
          size={20}
          cursor="pointer"
          onClick={() => setShowDelete(true)}
          color="red"
        />
      </div>
    </div>
  )
}

export default DayItem
