import ListModal from 'components/modal/ListModal'
import { AnimatePresence } from 'framer-motion'
import { useState } from 'react'
import { FaUsers } from 'react-icons/fa'

interface ItemProps {
  data: any
}
const CurrentActiveUsers = ({ data }: ItemProps) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <div
      className="flex-1 rounded-lg p-5"
      style={{
        boxShadow: '0px 0px 100px #8080804D'
      }}>
      <AnimatePresence initial={false}>
        {showModal && <ListModal title={'Active'} handleCloseModal={() => setShowModal(false)} />}
      </AnimatePresence>
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-[30px]">{data}</h1>
          <p>Recently Active Users</p>
        </div>
        <FaUsers size={34} color="#A854F7" />
      </div>

      <div
        onClick={() => setShowModal(true)}
        className="flex-1 mt-5 hover:opacity-50 cursor-pointer">
        <div className="flex items-center gap-[5px]">
          <div className="rounded-md h-[30px] w-[25px] bg-purple-200" />
          <div className="rounded-md h-[55px] w-[25px] bg-purple-500" />
          <div className="rounded-md h-[55px] w-[25px] bg-purple-200" />
          <div className="rounded-md h-[25px] w-[25px] bg-purple-200" />
        </div>
      </div>
    </div>
  )
}

export default CurrentActiveUsers
