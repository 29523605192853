// const BASE_URL = 'https://barilifestyle-api-13a58f2c9934.herokuapp.com/'
// const BASE_URL = 'http://localhost:8080/'
const BASE_URL = 'https://bariadmin.barilifestyle.com:2222/fitnessapi/'
export const UPLOAD_URL = 'https://bariadmin.barilifestyle.com:2222/uploader/upload'

export const API = {
  login: `${BASE_URL}auth/login?type=admin`,
  addTip: `${BASE_URL}tips/add`,
  addBulkTips: `${BASE_URL}tips/bulk-add`,
  updateTip: `${BASE_URL}tips/update`,
  deleteTip: `${BASE_URL}tips/delete/`,
  getTips: `${BASE_URL}tips/`,
  addNotification: `${BASE_URL}notifications/add`,
  getNotifications: `${BASE_URL}notifications/admin`,
  getNotification: `${BASE_URL}notification/`,
  copyNotification: `${BASE_URL}notification/copy/`,
  deleteNotification: `${BASE_URL}notifications/delete/`,
  sendNotification: `${BASE_URL}notifications/broadcast/`,
  getAppStatus: `${BASE_URL}app-status`,
  saveAppStatus: `${BASE_URL}app-status/save`,
  getUsers: `${BASE_URL}users/all`,
  getDashboardUsers: `${BASE_URL}users/dashboard`,
  exportAllUsers: `${BASE_URL}users/export-all`,
  getUser: `${BASE_URL}user/profile/`,
  updateUser: `${BASE_URL}users/save`,
  getUserPosts: `${BASE_URL}posts/user/`,
  getAdminProfile: `${BASE_URL}user/profile`,
  createUser: `${BASE_URL}users/create`,
  addBulkUsers: `${BASE_URL}users/bulk-add`,
  addBulkPosts: `${BASE_URL}posts/bulk-add`,
  deleteUser: `${BASE_URL}user/delete/`,
  takeAction: `${BASE_URL}user/take-action`,
  deletePost: `${BASE_URL}posts/delete/`,
  getPosts: `${BASE_URL}posts/feed`,
  addExercise: `${BASE_URL}exercise/save`,
  addBulkExercises: `${BASE_URL}exercise/bulk-add`,
  getExercises: `${BASE_URL}exercises/all`,
  getExercise: `${BASE_URL}exercise/`,
  deleteExercise: `${BASE_URL}exercise/delete/`,
  cloneExercise: `${BASE_URL}exercise/clone/`,
  addWorkoutPlan: `${BASE_URL}workout-plan/save`,
  addBulkWorkoutPlans: `${BASE_URL}workout-plan/bulk-add`,
  getWorkoutPlans: `${BASE_URL}workout-plans/all`,
  deleteWorkoutPlan: `${BASE_URL}workout-plan/delete/`,
  cloneWorkoutPlan: `${BASE_URL}workout-plan/clone/`,
  getWorkoutPlan: `${BASE_URL}workout-plan/view/`,
  addWorkoutPlanDay: `${BASE_URL}workout-plan/add-day`,
  addBulkWorkoutPlanDays: `${BASE_URL}workout-plan/bulk-add-day`,
  addBulkWorkoutPlanDayExercises: `${BASE_URL}workout-plan/bulk-add-day-exercise`,
  getWorkoutPlanDay: `${BASE_URL}workout-plan/day/`,
  deleteWorkoutPlanDay: `${BASE_URL}workout-plan/day/delete/`,
  deleteDayExercise: `${BASE_URL}workout-plan/day-exercise/delete/`,
  cloneWorkoutPlanDay: `${BASE_URL}workout-plan/day/clone/`,
  updatePlanPositions: `${BASE_URL}workout-plan/update-position`,
  resetUserPassword: `${BASE_URL}admin-reset-password`,
  getTrophies: `${BASE_URL}trophies`,
  saveTrophy: `${BASE_URL}trophy/save`,
  deleteTrophy: `${BASE_URL}trophy/delete/`,
  getReports: `${BASE_URL}reports/all`,
  getReport: `${BASE_URL}report/`,
  dashboard: `${BASE_URL}dashboard`,
  getPost: `${BASE_URL}post/`,
  getComments: `${BASE_URL}post/comments/`,
  deleteComment: `${BASE_URL}post/delete-comment/`,
  getTrophy: `${BASE_URL}trophy/`,
  sendReportMessage: `${BASE_URL}report/send-message/`
}
