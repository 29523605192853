import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface MenuItemProps {
  path: string
  active: boolean
  label: string
  icon?: ReactNode
}

const MenuItem = ({ path, label, active, icon = undefined }: MenuItemProps) => {
  return (
    <Link to={path}>
      <div
        className="flex items-center gap-2 px-4 py-2 my-3 rounded-lg"
        style={{
          background: active
            ? 'linear-gradient(90.5deg, #5BC0A1 -21.2%, #94CA65 52.32%, #B1D147 133.52%)'
            : '#F9F9F9',
          color: active ? 'white' : 'black'
        }}>
        {icon !== undefined && icon}
        <p>{label}</p>
      </div>
    </Link>
  )
}

export default MenuItem
