import ListModal from 'components/modal/ListModal'
import { AnimatePresence } from 'framer-motion'
import { useMemo, useState } from 'react'
import { MdPhoneIphone } from 'react-icons/md'

interface ItemProps {
  data: any
}
const DeviceType = ({ data }: ItemProps) => {
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const androidPercent = useMemo(() => {
    if (data) {
      const total = parseInt(data.android) + parseInt(data.ios)
      return parseInt(data.android) / total
    }
    return 1
  }, [data])

  const iosPercent = useMemo(() => {
    if (data) {
      const total = parseInt(data.android) + parseInt(data.ios)
      return parseInt(data.ios) / total
    }
    return 1
  }, [data])

  const handleShowData = (type: string) => {
    setShowModal(true)
    setModalTitle(`${type}`)
  }

  return (
    <div
      className="flex-1 rounded-lg p-5"
      style={{
        boxShadow: '0px 0px 100px #8080804D'
      }}>
      <AnimatePresence initial={false}>
        {showModal && <ListModal title={modalTitle} handleCloseModal={() => setShowModal(false)} />}
      </AnimatePresence>
      <div className="flex items-center justify-between">
        <div className="cursor-pointer" onClick={() => handleShowData('Android')}>
          <h1 className="text-[25px]">{data?.android}</h1>
          <p>Android Users</p>
        </div>
        <div className="cursor-pointer" onClick={() => handleShowData('ios')}>
          <h1 className="text-[25px]">{data?.ios}</h1>
          <p>IOS Users</p>
        </div>
        <MdPhoneIphone size={34} color="#EAB305" />
      </div>

      <div className="flex-1 mt-5">
        <div className="flex items-end gap-[10px]">
          <div className="flex flex-col items-center justify-center">
            <div
              onClick={() => handleShowData('Android')}
              style={{
                height: 55 * androidPercent
              }}
              className={`rounded-md w-[25px] hover:opacity-50 cursor-pointer bg-yellow-500`}
            />
            <p className="text-sm">Android</p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div
              onClick={() => handleShowData('ios')}
              style={{
                height: 55 * iosPercent
              }}
              className="rounded-md w-[25px] hover:opacity-50 cursor-pointer bg-blue-500"
            />
            <p className="text-sm">IOS</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeviceType
