import { API } from 'api/ApiConfig'
import { deleteApiHelper } from 'api/ApiHelper'
import Author from 'components/Author'
import DeleteModal from 'components/modal/DeleteModal'
import { AnimatePresence, useScroll } from 'framer-motion'
import moment from 'moment'
import { useState } from 'react'
import { AiTwotoneDelete, AiTwotoneEdit } from 'react-icons/ai'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

interface TipItemProps {
  tip?: any
  refresh?: () => void
}
const TipItem = ({ tip, refresh = () => {} }: TipItemProps) => {
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate()
  const edit = () => {
    navigate(`/tips/edit/${tip.id}`)
  }

  const deleteTip = () => {
    deleteApiHelper(
      `${API.deleteTip}${tip.id}`,
      () => {
        close()
        toast('Tip deleted')
        refresh()
      },
      (err) => {
        toast.error(err.toString())
      }
    )
  }

  const open = () => {
    setShowModal(true)
  }

  const close = () => {
    setShowModal(false)
  }

  return (
    <div className="flex border-t-[#8080802E] border-t-[1px] gap-[10px] py-2 px-1 items-center">
      <AnimatePresence initial={false}>
        {showModal && (
          <DeleteModal
            description="You are about to delete this tip, you cannot reverse this action"
            handleDelete={deleteTip}
            handleCloseModal={close}
          />
        )}
      </AnimatePresence>
      <img src={tip?.image} className="object-contain w-[50px] h-[50px]" alt="" />
      <p className="w-[30%] capitalize font-bold text-sm">{tip.title}</p>
      <p className="text-sm flex-1">{tip.likes.length ?? 0} Likes</p>
      <p className="flex-1 text-sm">{moment(tip.createdAt).format('DD-MMM-YYYY')}</p>
      <div className="flex-1">
        <Author author={tip.author} />
      </div>
      <div className="flex items-center gap-[10px] flex-1">
        <AiTwotoneEdit size={20} onClick={edit} cursor="pointer" color="#94CA65" />
        <AiTwotoneDelete size={20} cursor="pointer" onClick={open} color="red" />
      </div>
    </div>
  )
}

export default TipItem
