import { useNavigate } from 'react-router'
import { IoIosArrowBack } from 'react-icons/io'

interface InnerPageProps {
  title: string
  hasAction?: boolean
  loading?: boolean
  action?: () => void
}
const InnerPageHeader = ({
  title,
  hasAction = false,
  loading = false,
  action = () => {}
}: InnerPageProps) => {
  const navigate = useNavigate()

  const back = () => {
    navigate(-1)
  }

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-[10px]">
        <IoIosArrowBack cursor="pointer" size={20} onClick={back} />
        <h1 className="font-bold text-[20px]">{title}</h1>
      </div>
      {hasAction && (
        <button
          disabled={loading}
          onClick={action}
          className="text-sm text-white h-[40px] rounded-[10px] px-1 min-w-[100px]"
          style={{
            background: loading
              ? '#808080CC'
              : 'linear-gradient(90.5deg, #5BC0A1 -21.2%, #94CA65 52.32%, #B1D147 133.52%)'
          }}>
          Save
        </button>
      )}
    </div>
  )
}

export default InnerPageHeader
