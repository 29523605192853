import { API } from 'api/ApiConfig'
import { postApiHelper, uploadImageApiHelper } from 'api/ApiHelper'
import ImageUploader from 'components/Image/ImageUploader'
import InnerPageHeader from 'components/header/InnerPageHeader'
import { useRef, useState } from 'react'
import { toast } from 'react-toastify'

const AddWorkoutPlan = () => {
  const fileRef = useRef<any>()
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState('')
  const [image, setImage] = useState('')
  const [imageFile, setImageFile] = useState<any>(undefined)
  const [description, setDescription] = useState('')

  const saveWorkout = async () => {
    if (!title) {
      toast.error('Please enter a title!')
      return
    }
    if (!description) {
      toast.error('Please enter the description!')
      return
    }
    if (!imageFile) {
      toast.error('Please select an image')
      return
    }
    setLoading(true)
    const imageURL = await uploadImageApiHelper(imageFile)
    postApiHelper(
      API.addWorkoutPlan,
      { name: title, description, image: imageURL },
      (data) => {
        console.log(data)
        setLoading(false)
      },
      (err) => {
        toast.error(err.toString())
        setLoading(false)
      }
    )
  }

  const handleFileChange = (e: any) => {
    setImageFile(e.target.files[0])
    setImage(URL.createObjectURL(e.target.files[0]))
  }

  return (
    <div className="px-5">
      <InnerPageHeader hasAction loading={loading} action={saveWorkout} title="Add Workout Plan" />
      <div className="mt-10">
        <ImageUploader imageFile={image} fileRef={fileRef} handleFileChange={handleFileChange} />
        <div className="mt-5"></div>
        <p className="font-bold text-sm mb-1">Title</p>
        <input
          value={title}
          onChange={(e: any) => setTitle(e.target.value)}
          type="text"
          className="bg-[#8080800D] w-full outline-none p-1"
          placeholder="Enter title"
        />
        <div className="mt-5"></div>
        <p className="font-bold text-sm mb-1">Description</p>
        <textarea
          value={description}
          onChange={(e: any) => setDescription(e.target.value)}
          className="bg-[#8080800D] w-full min-h-[200px] outline-none p-1"
          placeholder="Enter title"
        />
      </div>
    </div>
  )
}

export default AddWorkoutPlan
