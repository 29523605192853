import { ImageAssets } from 'assets/ImageAssets'
import { Link } from 'react-router-dom'

interface AuthorProps {
  author?: any
}
const Author = ({ author }: AuthorProps) => {
  return (
    <Link to={`/users/${author?.id}`}>
      <div className="flex flex-1 gap-2 items-center">
        <img
          className="w-[30px] h-[30px] rounded-full"
          src={author?.profile_picture ?? ImageAssets.BariLogo}
          alt=""
        />
        <div>
          <p className="font-bold text-sm">{author?.fullName}</p>
          <p className="text-xs">@{author?.username}</p>
        </div>
      </div>
    </Link>
  )
}

export default Author
