import { API } from 'api/ApiConfig'
import { getApiHelper } from 'api/ApiHelper'
import PageHeader from 'components/header/PageHeader'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import TrophyItem from './components/TrophyItem'

const TrophiesPage = () => {
  const navigate = useNavigate()
  const [trophies, setTrophies] = useState([])
  const addTrophy = () => {
    navigate('/trophies/add')
  }

  const getTrophies = () => {
    getApiHelper(
      API.getTrophies,
      (data) => {
        setTrophies(data)
        console.log(data)
      },
      (err) => {
        console.log(err)
      }
    )
  }

  useEffect(() => {
    getTrophies()
  }, [])

  return (
    <div className="px-5">
      <PageHeader hasAdd action={addTrophy} actionTitle="Add Trophy" title="Trophies" />
      <p className="text-sm">View all trophies of the BariLifetsyle platform</p>
      <div className="flex font-bold items-center px-2 gap-[10px] mt-[10px] py-3 bg-[#8080801A]">
        <h4 className="text-sm w-[20%]">Name</h4>
        <h4 className="w-[30%]">Description</h4>
        <h4 className="w-[15%]">Type</h4>
        <h4 className="flex-1 text-sm">Date Created</h4>
        <h4 className="flex-1 text-sm">Actions</h4>
      </div>
      {trophies.map((item: any) => (
        <TrophyItem refresh={getTrophies} key={item.id} trophy={item} />
      ))}
    </div>
  )
}

export default TrophiesPage
