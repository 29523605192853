import { motion } from 'framer-motion'
import { dropIn } from 'utils/variants'
interface LogoutModalProps {
  handleSignout: () => void
  handleCloseModal: () => void
}

const LogoutModal = ({ handleSignout, handleCloseModal }: LogoutModalProps) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={handleCloseModal}
      className="h-screen z-20 flex items-center justify-center w-screen fixed top-0 left-0 bg-[##8080802E]">
      <motion.div
        initial={{ opacity: 1 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="absolute top-0 left-0 right-0 bottom-0 bg-[#8080802E]"
        style={{
          backdropFilter: 'blur(2px)'
        }}></motion.div>
      <motion.div
        style={{
          background: 'linear-gradient(90.5deg, #5BC0A1 -21.2%, #94CA65 52.32%, #B1D147 133.52%)'
        }}
        onClick={(e) => e.stopPropagation()}
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={dropIn}
        className="w-[400px] p-10 rounded-lg flex flex-col items-center">
        <h3 className="text-white font-bold text-[30px]">Logout</h3>
        <p className="text-white text-center text-sm">
          You are trying to logout, select confirm to logout
        </p>
        <div className="flex mt-5 items-center gap-5">
          <button
            className="h-[40px] rounded-lg bg-white text-black text-sm px-5"
            onClick={handleCloseModal}>
            Cancel
          </button>
          <button
            className="h-[40px] rounded-lg bg-red-600 text-white text-sm px-5"
            onClick={handleSignout}>
            Logout
          </button>
        </div>
      </motion.div>
    </motion.div>
  )
}

export default LogoutModal
