// @ts-nocheck
import axios from 'axios'
import { KEYS } from 'constants/KEYS'
import Cookies from 'js-cookie'
import { UPLOAD_URL } from './ApiConfig'

export const uploadImageApiHelper = async (file: any) => {
  const formData = new FormData()
  formData.append('content', file)

  const imageUrl = await fetch(UPLOAD_URL, {
    method: 'POST',
    body: formData
  })

  const parsedData = await imageUrl.text()
  return parsedData
}

export const getApiHelper = async (
  url: string,
  success: (...args: any) => any,
  failure: (...args: any) => any
) => {
  return await new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${Cookies.get(KEYS.token)}` }
      })
      .then(({ data }) => {
        if (data) {
          if (data.success) {
            if (data.data) {
              success(data.data)
              resolve(data.data)
            } else {
              success(data.message)
              resolve(data.message)
            }
          } else {
            failure(data.message)
            reject(data.message)
          }
        } else {
          failure('Something went wrong')
          reject(new Error('Something went wrong'))
        }
      })
      .catch((err) => {
        failure(err)
        reject(err)
      })
  })
}

export const postApiHelper = async (
  url: string,
  body: any,
  success: (...args: any) => any,
  failure: (...args: any) => any
) => {
  return await new Promise((resolve, reject) => {
    axios
      .post(url, body, {
        headers: { Authorization: `Bearer ${Cookies.get(KEYS.token)}` }
      })
      .then(({ data }) => {
        if (data) {
          if (data.success) {
            if (data.data) {
              success(data.data)
              resolve(data.data)
            } else {
              success(data.message)
              resolve(data.message)
            }
          } else {
            failure(data.message)
            reject(data.message)
          }
        } else {
          failure('Something went wrong')
          reject(new Error('Something went wrong'))
        }
      })
      .catch((err) => {
        failure(err)
        reject(err)
      })
  })
}

export const deleteApiHelper = async (
  url: string,
  success: (...args: any) => any,
  failure: (...args: any) => any
) => {
  return await new Promise((resolve, reject) => {
    axios
      .delete(url, {
        headers: { Authorization: `Bearer ${Cookies.get(KEYS.token)}` }
      })
      .then(({ data }) => {
        if (data) {
          if (data.success) {
            if (data.data) {
              success(data.data)
              resolve(data.data)
            } else {
              success(data.message)
              resolve(data.message)
            }
          } else {
            failure(data.message)
            reject(data.message)
          }
        } else {
          failure('Something went wrong')
          reject(new Error('Something went wrong'))
        }
      })
      .catch((err) => {
        failure(err)
        reject(err)
      })
  })
}
