interface ItemProps {
  imageFile: any
  fileRef: any
  handleFileChange: any
  imageSize?: 'small' | 'large'
}
const ImageUploader = ({
  imageFile,
  fileRef,
  handleFileChange,
  imageSize = 'large'
}: ItemProps) => {
  return (
    <div className="flex gap-[10px] items-end">
      <img
        src={imageFile}
        className={`${
          imageSize === 'small' ? 'h-[150px] w-[150px]' : 'h-[250px] w-[250px]'
        } object-contain bg-[#8080801A]`}
        alt=""
      />
      <button
        onClick={() => {
          fileRef.current.click()
        }}
        className="text-white p-2 text-sm rounded-[50px]"
        style={{
          background: 'linear-gradient(90.5deg, #5BC0A1 -21.2%, #94CA65 52.32%, #B1D147 133.52%)'
        }}>
        Select
      </button>
      <input ref={fileRef} className="invisible w-[10px]" type="file" onChange={handleFileChange} />
    </div>
  )
}

export default ImageUploader
