import { API } from 'api/ApiConfig'
import { deleteApiHelper, postApiHelper } from 'api/ApiHelper'
import DeleteModal from 'components/modal/DeleteModal'
import { AnimatePresence } from 'framer-motion'
import moment from 'moment'
import CloneModal from 'pages/exercises/components/CloneModal'
import { useState } from 'react'
import { AiTwotoneCopy, AiTwotoneDelete, AiTwotoneEdit } from 'react-icons/ai'
import { RxDragHandleHorizontal } from 'react-icons/rx'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

interface ItemProps {
  plan?: any
  refresh?: any
}
const WorkoutPlanItem = ({ plan, refresh }: ItemProps) => {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [cloneModal, setCloneModal] = useState(false)
  const [cloning, setCloning] = useState(false)
  const deletePlan = () => {
    deleteApiHelper(
      `${API.deleteWorkoutPlan}${plan.id}`,
      () => {
        toast('Workout plan deleted!')
        refresh()
      },
      (err) => {
        toast.error(err.toString())
      }
    )
  }
  const clonePlan = () => {
    setCloning(true)
    postApiHelper(
      `${API.cloneWorkoutPlan}${plan.id}`,
      {},
      (data) => {
        setCloning(false)
        toast('Workout plan cloned!')
        navigate(`/workout-plans/edit/${data.id}`)
        // refresh()
      },
      (err) => {
        setCloning(false)
        toast.error(err.toString())
      }
    )
  }
  return (
    <div className="flex border-b border-[#8080802E] items-center px-2 py-1 gap-[10px]">
      <AnimatePresence>
        {showModal && (
          <DeleteModal
            description="Are you sure you want to elete this workout plan"
            handleDelete={deletePlan}
            handleCloseModal={() => setShowModal(false)}
          />
        )}
        {cloneModal && (
          <CloneModal
            loading={cloning}
            handleClone={clonePlan}
            description="Make a copy of this workout plan. This will take a few minutes do not refresh this page"
            handleCloseModal={() => setCloneModal(false)}
          />
        )}
      </AnimatePresence>
      <RxDragHandleHorizontal />
      <div className="w-[12%]">
        <img className="h-[80px] object-contain w-[80px]" src={plan.image} alt="" />
      </div>
      <p className="text-sm w-[12%]">{plan.name}</p>
      <p className="text-sm flex-1">{plan.description}</p>
      <p className="w-[12%] text-sm">{moment(plan.createdAt).format('DD-MMM-YYYY')}</p>
      <div className="flex gap-[10px] w-[12%]">
        <Link to={`/workout-plans/edit/${plan.id}`}>
          <AiTwotoneEdit size={20} cursor="pointer" color="#94CA65" />
        </Link>
        <AiTwotoneCopy
          className="text-blue-400"
          size={20}
          cursor="pointer"
          onClick={() => setCloneModal(true)}
        />
        <AiTwotoneDelete
          size={20}
          cursor="pointer"
          onClick={() => setShowModal(true)}
          color="red"
        />
      </div>
    </div>
  )
}

export default WorkoutPlanItem
