import { API } from 'api/ApiConfig'
import { getApiHelper } from 'api/ApiHelper'
import PageHeader from 'components/header/PageHeader'
import { useEffect, useState } from 'react'
import ReportItem from './components/ReportItem'

const ReportsPage = () => {
  const [reports, setReports] = useState([])
  const getReports = () => {
    getApiHelper(
      API.getReports,
      (data) => {
        setReports(data)
      },
      (err) => {
        console.log(err)
      }
    )
  }

  useEffect(() => {
    getReports()
  }, [])

  return (
    <div className="px-5">
      <PageHeader title="Reports" hasAdd={false} />
      <p className="text-sm">View all reports from members of the community</p>
      <div className="mt-[10px]">
        <div className="flex font-bold items-center px-2 gap-[10px] mt-[10px] py-3 bg-[#8080801A]">
          <h4 className="flex-1 text-sm">Reason</h4>
          <h4 className="text-sm w-[30%]">Description</h4>
          <h4 className="flex-1 text-sm">Reporter</h4>
          <h4 className="flex-1 text-sm">Reported User</h4>
          <h4 className="flex-1 text-sm">Report Type</h4>
          <h4 className="flex-1 text-sm">Actions</h4>
        </div>
        {reports.map((item: any) => (
          <ReportItem report={item} key={item?.id} />
        ))}
      </div>
    </div>
  )
}

export default ReportsPage
