interface ItemProps {
  label: string
  value?: string
  onChange?: (val: string) => any
}
const UserDetailInput = ({ label, value, onChange = () => {} }: ItemProps) => {
  return (
    <div className="flex-1">
      <p className="text-xs font-semibold">{label}</p>
      <input
        disabled
        onChange={(e) => onChange(e.target.value)}
        value={value}
        type="text"
        className="h-[40px] w-full rounded-md px-2 py-1 border outline-none border-[#808080]"
      />
    </div>
  )
}

export default UserDetailInput
