import moment from 'moment'
import { Fragment, useState } from 'react'
import { AiTwotoneCopy, AiTwotoneDelete, AiTwotoneEdit } from 'react-icons/ai'
import NoticationStatus from './NotificationStatus'
import { AnimatePresence } from 'framer-motion'
import DeleteModal from 'components/modal/DeleteModal'
import { deleteApiHelper, getApiHelper } from 'api/ApiHelper'
import { API } from 'api/ApiConfig'
import { toast } from 'react-toastify'
import { BsFillSendFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import CloneModal from 'pages/exercises/components/CloneModal'

interface ItemProps {
  notification: any
  reload: () => void
}

const NotificationItem = ({ notification, reload }: ItemProps) => {
  const [showModal, setShowModal] = useState(false)
  const [showClone, setShowClone] = useState(false)
  const [loading, setLoading] = useState(false)

  const open = () => {
    setShowModal(true)
  }

  const close = () => {
    setShowModal(false)
  }

  const broadcast = () => {
    getApiHelper(
      `${API.sendNotification}${notification.id}`,
      () => {
        toast('Notifications sent to users')
        reload()
      },
      (err) => {
        toast.error(err.toString())
      }
    )
  }

  const deleteNotification = () => {
    deleteApiHelper(
      `${API.deleteNotification}${notification.id}`,
      () => {
        reload()
      },
      (err) => {
        toast.error(err.toString())
      }
    )
  }

  const cloneNotification = () => {
    setLoading(true)
    getApiHelper(
      `${API.copyNotification}${notification.id}`,
      (data) => {
        toast(data)
        setLoading(false)
        setShowClone(false)
        reload()
      },
      (err) => {
        setLoading(false)
        setShowClone(false)
        toast.error(err.toString())
      }
    )
  }

  return (
    <div className="flex border-t border-t-[#8080801A] items-center gap-[15px] px-2 py-3">
      <AnimatePresence initial={false}>
        {showModal && (
          <DeleteModal
            description="You are about to delete this notification, you cannot reverse this action"
            handleDelete={deleteNotification}
            handleCloseModal={close}
          />
        )}
        {showClone && (
          <CloneModal
            loading={loading}
            description="Clone this notification?"
            handleClone={cloneNotification}
            handleCloseModal={() => setShowClone(false)}
          />
        )}
      </AnimatePresence>
      <p className="w-[20%] font-bold text-[14px]">{notification?.title}</p>
      <p className="flex-1 font-semibold text-[14px]">{notification?.description}</p>
      <NoticationStatus status={notification?.status} />
      <p className="w-[15%] text-sm">
        {notification?.broadCastTime
          ? moment(notification.broadCastTime).format('DD-MMM-YYYY')
          : '--:--'}
      </p>
      <div className="w-[20%] flex items-center gap-[10px]">
        {notification?.status === 'Not sent' && (
          <Fragment>
            <BsFillSendFill size={20} onClick={broadcast} cursor="pointer" color="#94CA65" />
            <Link to={`edit/${notification?.id}`}>
              <AiTwotoneEdit size={20} cursor="pointer" className="text-blue-500" onClick={open} />
            </Link>
          </Fragment>
        )}
        <AiTwotoneCopy
          size={20}
          cursor="pointer"
          onClick={() => setShowClone(true)}
          className="text-yellow-500"
        />

        <AiTwotoneDelete size={20} cursor="pointer" onClick={open} color="red" />
      </div>
    </div>
  )
}

export default NotificationItem
