import ReactPaginate from 'react-paginate'

interface ItemProps {
  pageSize: string
  setPageSize: (value: string) => any
  totalPages: number
  onPageChange: (page: number) => void
}

const TableFooter = ({ pageSize, setPageSize, totalPages, onPageChange }: ItemProps) => {
  return (
    <div className="flex font-bold justify-between items-center px-2 py-3 mt-2 bg-[#8080801A]">
      <div className="flex items-center gap-2">
        <p className="text-xs">Items per page</p>
        <select
          value={pageSize}
          onChange={(e) => setPageSize(e.target.value)}
          className="bg-transparent outline-none"
          name=""
          id="">
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
      <ReactPaginate
        pageClassName="border border-transparent h-6 w-6 flex items-center justify-center rounded-full"
        previousClassName="mr-[20px] text-[20px] text-[#B1D147]"
        nextClassName="text-[#B1D147] ml-[20px] text-[20px]"
        containerClassName="flex items-center gap-2"
        activeClassName="bg-white"
        breakLabel="..."
        nextLabel=">"
        onPageChange={(selected) => onPageChange(selected.selected)}
        pageRangeDisplayed={5}
        pageCount={totalPages}
        previousLabel="<"
        renderOnZeroPageCount={null}
      />
    </div>
  )
}

export default TableFooter
