import { API } from 'api/ApiConfig'
import { getApiHelper, postApiHelper } from 'api/ApiHelper'
import InnerPageHeader from 'components/header/InnerPageHeader'
import { useEffect, useState } from 'react'
import AddExerciseSection from '../components/AddExerciseSection'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import DayExerciseItem from '../components/DayExerciseItem'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { v4 as uuidv4 } from 'uuid'

const WorkoutPlanDay = () => {
  const [title, setTitle] = useState('')
  const navigate = useNavigate()
  const [exercises, setExercises] = useState([])
  const [sortIndex, setSortIndex] = useState(0)
  const [selectedExercises, setSelectedExercises] = useState<any>([])
  const [selectedDayExercise, setSelectedDayExercise] = useState(-1)

  const handleSelectDayExercise = (value: number) => {
    setSelectedDayExercise(value)
    console.log(selectedExercises[value])
  }

  const saveDay = () => {
    if (!title) {
      toast.error('Please enter a title!')
      return
    }
    const body: any = {
      id: location.pathname.split('/')[4] === 'add' ? uuidv4() : location.pathname.split('/')[4],
      title,
      planId: location.pathname.split('/')[2],
      sortIndex,
      dayExercises: selectedExercises.map((item: any, index: number) => ({
        ...item,
        sortIndex: index,
        workoutPlanDay: {
          id: location.pathname.split('/')[4] === 'add' ? uuidv4() : location.pathname.split('/')[4]
        }
      }))
    }
    postApiHelper(
      API.addWorkoutPlanDay,
      body,
      () => {
        toast('Workout plan day saved successfully!')
        navigate(-1)
      },
      (err) => {
        toast.error(err.toString())
      }
    )
  }

  const getExercises = () => {
    getApiHelper(
      `${API.getExercises}?size=999`,
      (data) => {
        setExercises(
          data.content?.sort((a: any, b: any) => {
            // Convert names to lowercase for case-insensitive sorting
            const nameA = a.name.toLowerCase()
            const nameB = b.name.toLowerCase()

            // Compare the names
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0
          })
        )
      },
      (err) => {
        console.log(err)
      }
    )
  }

  const getDay = () => {
    if (location.pathname.split('/')[4] !== 'add') {
      // get the day
      getApiHelper(
        `${API.getWorkoutPlanDay}${location.pathname.split('/')[4]}`,
        (data) => {
          if (data) {
            setSortIndex(data.sortIndex)
            setSelectedExercises(
              data.dayExercises?.sort((a: any, b: any) => a.sortIndex - b.sortIndex)
            )
            setTitle(data.title)
          }
        },
        (err) => {
          toast.error(err.toString())
          navigate(-1)
        }
      )
    }
  }

  const selectExercise = (exercise: any) => {
    const newArray = [...selectedExercises]
    const itemIndex = newArray.findIndex((item) => item.id === exercise.id)
    if (itemIndex > -1) {
      newArray[itemIndex] = exercise
    } else {
      newArray.push(exercise)
    }
    setSelectedExercises(newArray)
    setSelectedDayExercise(-1)
  }

  const removeExercise = (id: string) => {
    const newArray = [...selectedExercises]
    const item = newArray.findIndex((item: any) => item.id === id)
    if (item > -1) {
      newArray.splice(item, 1)
    }
    setSelectedExercises(newArray)
  }

  useEffect(() => {
    getDay()
    getExercises()
  }, [])

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }
    const reorderedItems: any = Array.from(selectedExercises)
    const [removed] = reorderedItems.splice(result.source.index, 1)
    reorderedItems.splice(result.destination.index, 0, removed)
    setSelectedExercises(reorderedItems)
    setSelectedDayExercise(-1)
  }

  return (
    <div className="px-5">
      <InnerPageHeader hasAction action={saveDay} title="Daily Exercises" />
      <p className="text-sm">Add the daily exercises for the selected workout plan</p>
      <div className="mt-5">
        <p className="font-bold text-sm mb-1">Title</p>
        <input
          value={title}
          onChange={(e: any) => setTitle(e.target.value)}
          type="text"
          className="bg-[#8080800D] w-full outline-none p-1"
          placeholder="Enter title"
        />
        <div className="mt-5"></div>
        <p className="font-bold text-sm">Exercises</p>
        <div className="flex gap-5 items-start">
          {/* list of all exercises added */}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div className="flex-1" {...provided.droppableProps} ref={provided.innerRef}>
                  {selectedExercises.map((item: any, index: number) => {
                    let exercise: any = exercises.find((mItem: any) => mItem.id === item.exerciseId)
                    if (!exercise) {
                      exercise = item.exercise
                    }
                    return (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided) => (
                          <div
                            style={{
                              ...provided.draggableProps.style
                            }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            <DayExerciseItem
                              selected={selectedDayExercise === index}
                              select={() => {
                                handleSelectDayExercise(index)
                              }}
                              item={item}
                              exercise={exercise}
                              key={item.id}
                              removeFromList={removeExercise}
                            />
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {/* add exercise logic */}
          {exercises && (
            <AddExerciseSection
              workoutVal={
                selectedDayExercise > -1
                  ? {
                      ...selectedExercises[selectedDayExercise],
                      exerciseId: selectedExercises[selectedDayExercise].exercise?.id ?? ''
                    }
                  : undefined
              }
              cancel={() => setSelectedDayExercise(-1)}
              selectedDayExercise={selectedDayExercise}
              selectExercise={selectExercise}
              exercises={exercises}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default WorkoutPlanDay
