import { API } from 'api/ApiConfig'
import { getApiHelper } from 'api/ApiHelper'
import PageHeader from 'components/header/PageHeader'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import PostItem from './components/PostItem'
import ReactPaginate from 'react-paginate'
import { useNavigate } from 'react-router'
const CommunityPage = () => {
  const navigate = useNavigate()
  const [posts, setPosts] = useState<any>()
  const [perPage, setPerPage] = useState('10')
  const searchParams = new URLSearchParams(location.search)
  const scrollRef = useRef<any>()
  const [page, setPage] = useState(searchParams.get('page') ?? 0)

  const getPosts = () => {
    getApiHelper(
      `${API.getPosts}?page=${page}&size=${perPage}`,
      (data) => {
        setPosts(data)
        scrollRef.current.scrollIntoView({ behaviour: 'smooth' })
      },
      (err) => {
        toast.error(err)
      }
    )
  }

  useEffect(() => {
    getPosts()
  }, [page, perPage])

  return (
    <div className="px-5">
      <PageHeader hasAdd={true} title="Community" />
      <p ref={scrollRef} className="text-sm">
        View all posts from members of the community
      </p>
      <div className="mt-10">
        {posts?.content?.map((item: any) => (
          <PostItem refresh={getPosts} post={item} key={item.id} />
        ))}
      </div>
      {posts?.content?.length && (
        <div className="flex font-bold justify-between items-center px-2 py-3 mt-2 bg-[#8080801A]">
          <div className="flex items-center gap-2">
            <p className="text-xs">Items per page</p>
            <select
              value={perPage}
              onChange={(e) => {
                setPerPage(e.target.value)
              }}
              className="bg-transparent outline-none"
              name=""
              id="">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <ReactPaginate
            pageClassName="border border-transparent h-6 w-6 flex items-center justify-center rounded-full"
            previousClassName="mr-[20px] text-[20px] text-[#B1D147]"
            nextClassName="text-[#B1D147] ml-[20px] text-[20px]"
            containerClassName="flex items-center gap-2"
            activeClassName="bg-white"
            breakLabel="..."
            nextLabel=">"
            initialPage={parseInt(page.toString(), 10)}
            onPageChange={(selected) => {
              setPage(selected.selected)
              if (selected.selected === 0) {
                navigate(``)
              } else {
                navigate(`?page=${selected.selected}`)
              }
            }}
            pageRangeDisplayed={5}
            pageCount={posts?.totalPages ?? 0}
            previousLabel="<"
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </div>
  )
}

export default CommunityPage
