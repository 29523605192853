import React from 'react'
interface ItemProps {
  label: string
  value: string
  onChange: (e: any) => void
}
const StatusItem = ({ label, value, onChange }: ItemProps) => {
  return (
    <div className="flex mb-3 gap-[20px] justify-between items-center">
      <p className="w-[30%] text-sm font-bold">{label}</p>
      <input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="outline-none px-2 h-[40px] flex-1 rounded-[10px] bg-[#8080801A] w-[100px]"
        type="text"
      />
    </div>
  )
}

export default StatusItem
