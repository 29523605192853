import PageHeader from 'components/header/PageHeader'
import StatusItem from './components/StatusItem'
import Switch from 'react-switch'
import { useEffect, useState } from 'react'
import { getApiHelper, postApiHelper } from 'api/ApiHelper'
import { API } from 'api/ApiConfig'
import { toast } from 'react-toastify'

const AppStatusPage = () => {
  const [appActive, setAppActive] = useState(false)
  const [androidVersion, setAndroidVersion] = useState('')
  const [iosVersion, setIOSVersion] = useState('')
  const [birthdayMessage, setBirthdayMessage] = useState('')

  const saveStatus = () => {
    postApiHelper(
      API.saveAppStatus,
      { androidVersion, iosVersion, appActive, birthdayMessage },
      () => {
        toast('Status updated!')
      },
      (err) => {
        toast.error(err.toString())
      }
    )
  }

  const getAppStatus = () => {
    getApiHelper(
      API.getAppStatus,
      (data) => {
        setBirthdayMessage(data.birthdayMessage)
        setAndroidVersion(data.androidVersion)
        setIOSVersion(data.iosVersion)
        setAppActive(data.appActive)
      },
      (err) => {
        toast.error(err.toString())
      }
    )
  }

  useEffect(() => {
    getAppStatus()
  }, [])

  return (
    <div className="px-5">
      <PageHeader actionTitle="Save" hasAdd action={saveStatus} title="App Status" />
      <p className="text-sm">
        Adjust application version and turn on / off the application service
      </p>
      <div className="mt-10 w-[40%]">
        <div className="flex mb-3 gap-[20px] items-center">
          <p className="w-[30%] text-sm font-bold">App Service</p>
          <Switch onChange={(checked) => setAppActive(checked)} checked={appActive} />
        </div>
        <StatusItem value={androidVersion} onChange={setAndroidVersion} label="Android Version" />
        <StatusItem value={iosVersion} onChange={setIOSVersion} label="IOS Version" />
        <div className="mt-3">
          <p className="w-[30%] text-sm font-bold">Birthday Message</p>
          <textarea
            value={birthdayMessage}
            onChange={(inp) => setBirthdayMessage(inp.target.value)}
            name=""
            className="h-[150px] p-2 w-full outline-none rounded-[10px] bg-[#8080801A]"
            id=""></textarea>
        </div>
      </div>
    </div>
  )
}

export default AppStatusPage
