import { API } from 'api/ApiConfig'
import { deleteApiHelper, getApiHelper, postApiHelper } from 'api/ApiHelper'
import Author from 'components/Author'
import InnerPageHeader from 'components/header/InnerPageHeader'
import DeleteModal from 'components/modal/DeleteModal'
import PostItem from 'pages/community/components/PostItem'
import TakeActionModal from 'pages/users/components/TakeActionModal'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

const ReportActionPage = () => {
  const [loading, setLoading] = useState(false)
  const [showDeleteReport, setShowDeleteReport] = useState(false)
  const [showBlockModal, setShowBlockModal] = useState(false)
  const navigate = useNavigate()
  const [report, setReport] = useState<any>(null)
  const [message, setMessage] = useState('')

  const getReport = () => {
    getApiHelper(
      `${API.getReport}${location.pathname.split('/')[2]}`,
      (data) => {
        setReport(data)
        // console.log(data)
      },
      (err) => {
        console.log(err)
      }
    )
  }

  const handleBlockUser = () => {
    postApiHelper(
      API.takeAction,
      { action: 'Block', userId: report?.reported?.id ?? report?.reportedPost?.author?.id },
      (data) => {
        setShowBlockModal(false)
        toast(data)
        alert('User has been blocked')
        navigate(-1)
      },
      (err) => {
        toast.error(err.toString())
      }
    ).catch((err) => {
      console.log(err)
    })
  }

  const handleSendMessage = () => {
    if (!message) {
      toast.error('Please enter the message you would like to send to the user')
      return
    }
    setLoading(true)
    postApiHelper(
      `${API.sendReportMessage}${location.pathname.split('/')[2]}`,
      { message },
      () => {
        toast('Message sent successfully!')
        setLoading(false)
        setMessage('')
      },
      (err) => {
        setLoading(false)
        toast.error(err?.toString())
      }
    )
  }

  const deleteReport = () => {
    deleteApiHelper(
      `${API.getReport}${report?.id}`,
      () => {
        alert('Report deleted!')
        navigate(-1)
      },
      (err) => {
        toast.error(err?.toString())
      }
    )
  }

  useEffect(() => {
    getReport()
  }, [])

  return (
    <div className="px-5">
      <InnerPageHeader title="Report Action" />
      {showDeleteReport && (
        <DeleteModal
          description="Delete this report"
          handleCloseModal={() => setShowDeleteReport(false)}
          handleDelete={deleteReport}
        />
      )}
      {showBlockModal && (
        <TakeActionModal
          title="Block User"
          description="Are you sure you want to block this user from using the application?"
          handleCloseModal={() => setShowBlockModal(false)}
          action={handleBlockUser}
        />
      )}
      <p className="text-sm">Take action on the report below</p>
      {report !== null && (
        <div className="mt-[20px] w-[60%]">
          <div className="flex mt-[10px] flex-row items-center gap-[10px]">
            <h5 className="w-[15%] text-sm mb-[5px]">Report Reason</h5>
            <p>{report?.reason} </p>
          </div>
          <div className="flex mt-[10px] flex-row items-center gap-[10px]">
            <h5 className="w-[15%] text-sm mb-[5px]">Description:</h5>
            <p>{report?.description} </p>
          </div>
          <div className="flex mt-[10px] flex-row items-center gap-[10px]">
            <h5 className="w-[15%] text-sm mb-[5px]">Reported By:</h5>
            <Author author={report?.reporter} />
          </div>
          <div className="flex mt-[10px] flex-row items-center gap-[10px]">
            <h5 className="w-[15%] text-sm mb-[5px]">
              Reported {report?.reported ? 'User' : 'Post'}:
            </h5>
            {report?.reported !== null ? (
              <Author author={report?.reported} />
            ) : (
              <PostItem
                refresh={() => {
                  navigate(-1)
                }}
                post={report?.reportedPost}
              />
            )}
          </div>
          <div className="mt-[20px]">
            <h5>Actions</h5>
            <div className="flex items-start gap-[10px]">
              <button
                disabled={loading}
                onClick={() => setShowBlockModal(true)}
                className="text-sm text-white h-[40px] rounded-[10px] px-2 min-w-[100px]"
                style={{
                  background: loading ? '#808080CC' : 'orange'
                }}>
                Block User
              </button>
              <button
                disabled={loading}
                onClick={() => setShowDeleteReport(true)}
                className="text-sm text-white h-[40px] rounded-[10px] px-2 min-w-[100px]"
                style={{
                  background: loading ? '#808080CC' : '#5BC0A1'
                }}>
                Delete Report
              </button>
            </div>
            <div className="mt-5">
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="h-[100px] w-full border-2 rounded-lg p-2 border-black/50 outline-none text-sm"
                name=""
                id=""></textarea>
              <br />
              <button
                disabled={loading}
                onClick={handleSendMessage}
                className="text-sm text-white h-[40px] rounded-[10px] px-2 min-w-[100px]"
                style={{
                  background: loading ? '#808080CC' : '#5BC0A1'
                }}>
                Send Message
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ReportActionPage
