import { API } from 'api/ApiConfig'
import { deleteApiHelper, getApiHelper, postApiHelper } from 'api/ApiHelper'
import { ImageAssets } from 'assets/ImageAssets'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import UserPostItem from './components/UserPostItem'
import UserDetailHeader from './components/UserDetailHeader'
import { AnimatePresence } from 'framer-motion'
import ConfirmModal from 'components/modal/ConfirmModal'
import UserDetailInput from './components/UserDetailInput'
import EnterPasswordModal from 'components/modal/EnterPasswordModal'
import { useNavigate } from 'react-router'

const UserDetailPage = () => {
  const navigate = useNavigate()
  const saveUser = () => {
    if (!user.email) {
      toast.error('Please provide a valid email address')
      return
    }
    if (!user.username) {
      toast.error('Please provide a valid username')
      return
    }
    console.log(user)
    postApiHelper(
      API.updateUser,
      user,
      (data) => {
        toast(data)
      },
      () => {}
    ).catch((err) => {
      toast.error(err.toString())
    })
  }
  const [user, setUser] = useState<any>()
  const [posts, setPosts] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [showBlock, setShowBlock] = useState(false)
  const [showUnblock, setShowUnblock] = useState(false)
  const [showReset, setShowReset] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [actionLoading, setActionLoading] = useState(false)

  const getUserPosts = () => {
    getApiHelper(
      `${API.getUserPosts}${location.pathname.split('/')[2]}`,
      (data) => {
        setPosts(data)
      },
      (err) => {
        toast.error(err)
      }
    ).catch((err) => {
      console.log(err)
    })
  }

  const getUserInformation = () => {
    setLoading(true)
    getApiHelper(
      `${API.getUser}${location.pathname.split('/')[2]}`,
      (data) => {
        setLoading(false)
        setUser(data)
      },
      (err) => {
        setLoading(false)
        toast.error(err.toString())
      }
    ).catch((err) => {
      console.log(err)
    })
  }

  const setUserInformation = (key: string, value: any) => {
    setUser((prev: any) => {
      const newVal: any = {}
      newVal[key] = value
      const newUser = Object.assign({}, prev, newVal)
      return newUser
    })
  }

  useEffect(() => {
    getUserInformation()
    getUserPosts()
  }, [])

  const blockUser = (password: string) => {
    postApiHelper(
      API.takeAction,
      { action: 'Block', userId: user.id, rawPassword: password },
      (data) => {
        setShowBlock(false)
        toast(data)
        getUserInformation()
      },
      (err) => {
        toast.error(err.toString())
      }
    ).catch((err) => {
      console.log(err)
    })
  }
  const unblockUser = (password: string) => {
    postApiHelper(
      API.takeAction,
      { action: 'Unblock', userId: user.id, rawPassword: password },
      (data) => {
        setShowUnblock(false)
        toast(data)
        getUserInformation()
      },
      (err) => {
        toast.error(err.toString())
      }
    ).catch((err) => {
      console.log(err)
    })
  }

  const deleteUser = (password: string) => {
    deleteApiHelper(
      `${API.deleteUser}${user.id}?rawPassword=${password}`,
      () => {
        setShowDelete(false)
        toast('User has been removed from the platform')
        navigate(-1)
      },
      (err) => {
        toast.error(err.toString())
      }
    ).catch((err) => console.log(err))
  }

  const resetUserPassword = () => {
    setActionLoading(true)
    setShowReset(false)
    getApiHelper(
      `${API.resetUserPassword}?userId=${location.pathname.split('/')[2]}`,
      (data) => {
        toast(data?.message ?? 'Password reset!')
        setActionLoading(false)
      },
      (err) => {
        console.log(err)
        setActionLoading(false)
      }
    )
  }

  return (
    <div className="px-5">
      <AnimatePresence>
        {showBlock && (
          <EnterPasswordModal
            title="Block User"
            description="Are you sure you want to block this user?"
            handleCloseModal={() => setShowBlock(false)}
            handleConfirm={blockUser}
          />
        )}
        {showUnblock && (
          <EnterPasswordModal
            title="Unblock User"
            description="Are you sure you want to unblock this user?"
            handleCloseModal={() => setShowUnblock(false)}
            handleConfirm={unblockUser}
          />
        )}
        {showDelete && (
          <EnterPasswordModal
            title="Delete User"
            description="You are about to delete this user, this action is not reversible?"
            handleCloseModal={() => setShowDelete(false)}
            handleConfirm={deleteUser}
          />
        )}
        {showReset && (
          <ConfirmModal
            handleConfirm={resetUserPassword}
            title="Reset User Password"
            description="You are about to reset this users password"
            handleCloseModal={() => setShowReset(false)}
          />
        )}
      </AnimatePresence>
      <UserDetailHeader
        banned={user?.banned ?? false}
        loading={actionLoading}
        handleReset={() => setShowReset(true)}
        handleBlock={() => setShowBlock(true)}
        handleUnblock={() => setShowUnblock(true)}
        handleDelete={() => setShowDelete(true)}
        title="User Information"
        action={saveUser}
      />
      {!loading && (
        <div className="mt-5 flex items-start gap-10">
          <div className="flex-1">
            {/* user information section */}
            <div className="shadow-md rounded-lg p-5">
              <div className="flex items-center justify-between ">
                <div className="flex gap-2 items-center">
                  <img
                    src={user?.profile_picture ?? ImageAssets.BariLogo}
                    className="w-10 h-10 rounded-full"
                    alt=""
                  />
                  <div>
                    <h2 className="text-sm">{user?.fullName}</h2>
                    <p className="text-xs opacity-50">@{user?.username}</p>
                  </div>
                </div>
                <p
                  title="This user is using an android device"
                  className="capitalize text-sm text-[#94CA65]">
                  {user?.lastDevice?.toLowerCase() ?? 'Android'} User
                </p>
              </div>
              <div className="mt-5">
                <h3 className="py-2 text-[#94CA65]">User Information</h3>
                <div className="flex gap-5 items-center justify-between">
                  <div className="flex flex-1 items-center gap-3">
                    <p className="text-xs w-[25%] font-bold">User Type</p>
                    <select
                      onChange={(e) => setUserInformation('role', e.target.value)}
                      value={user?.role}
                      className="outline-none flex-1 text-sm px-1 py-3"
                      name=""
                      id="">
                      <option value="REGULAR">Regular</option>
                      <option value="PREMIUM">Premium</option>
                      <option value="ADMIN">Admin</option>
                      <option value="SUBADMIN">Subadmin</option>
                    </select>
                  </div>
                  <div className="flex flex-1 mt-2 items-center w-full gap-3">
                    <p className="text-xs w-[25%] font-bold">Email:</p>
                    <input
                      onChange={(e) => setUserInformation('email', e.target.value)}
                      className="border-b border-[#808080] px-2 text-sm outline-none flex-1"
                      type="text"
                      value={user?.email}
                    />
                  </div>
                </div>
                <div className="flex mt-2 gap-5 items-center justify-between">
                  <div className="flex mt-2 items-center w-full gap-3">
                    <p className="text-xs w-[25%] font-bold">Name:</p>
                    <input
                      onChange={(e) => setUserInformation('fullName', e.target.value)}
                      className="border-b border-[#808080] px-2 text-sm outline-none flex-1"
                      type="text"
                      value={user?.fullName}
                    />
                  </div>
                  <div className="flex mt-2 items-center w-full gap-3">
                    <p className="text-xs w-[25%] font-bold">Username:</p>
                    <input
                      onChange={() => {}}
                      className="border-b border-[#808080] px-2 text-sm outline-none flex-1"
                      type="text"
                      value={user?.username}
                    />
                  </div>
                </div>
                <div className="flex mt-2 gap-5 items-center justify-between">
                  <div className="flex mt-2 items-center w-full gap-3">
                    <p className="text-xs w-[25%] font-bold">Gender:</p>
                    <select
                      onChange={(e) => setUserInformation('gender', e.target.value)}
                      value={user?.gender}
                      className="outline-none flex-1 text-sm px-1 py-3"
                      name=""
                      id="">
                      <option value="MALE">Male</option>
                      <option value="FEMALE">Female</option>
                      <option value="OTHER">Other</option>
                    </select>
                  </div>
                  <div className="flex mt-2 items-center w-full gap-3">
                    <p className="text-xs w-[25%] font-bold">Phone Number:</p>
                    <input
                      onChange={() => {}}
                      className="border-b border-[#808080] px-2 text-sm outline-none flex-1"
                      type="tel"
                      value={user?.phonenumber}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5 shadow-md rounded-lg p-5">
              <h3 className="py-2 text-[#94CA65]">More Information</h3>
              <div>
                <h4 className="text-md my-3 font-semibold">Body Information</h4>
                <div className="flex gap-10 items-center">
                  <UserDetailInput
                    value={user?.userInformation?.height ?? 'Not Set'}
                    label="Height"
                  />
                  <UserDetailInput
                    value={user?.userInformation?.weight ?? 'Not Set'}
                    label="Weight"
                  />
                </div>
                <div className="mt-3 flex gap-10 items-center">
                  <UserDetailInput
                    value={user?.userInformation?.wrist ?? 'Not Set'}
                    label="Wrist"
                  />
                  <UserDetailInput
                    value={user?.userInformation?.biceps ?? 'Not Set'}
                    label="Biceps"
                  />
                </div>
                <div className="mt-3 flex gap-10 items-center">
                  <UserDetailInput value={user?.userInformation?.hips ?? 'Not Set'} label="Hips" />
                  <UserDetailInput
                    value={user?.userInformation?.waist ?? 'Not Set'}
                    label="Waist"
                  />
                </div>
              </div>
              <div className="mt-3">
                <h4 className="text-md my-3 font-semibold">Daily Goals</h4>
                <div className="mt-3 flex gap-10 items-center">
                  <UserDetailInput
                    value={user?.userInformation?.dailySteps ?? 'Not Set'}
                    label="Daily Steps"
                  />
                  <UserDetailInput
                    value={user?.userInformation?.dailyWater ?? 'Not Set'}
                    label="Daily Water Intake"
                  />
                  <UserDetailInput
                    value={user?.userInformation?.dailyCalories ?? 'Not Set'}
                    label="Daily Calories"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 shadow-md rounded-lg px-5">
            <h1 className="py-5 text-[#94CA65]">Community Posts</h1>
            {posts?.map((item: any) => (
              <UserPostItem refresh={getUserPosts} key={item.id} post={item} />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default UserDetailPage
