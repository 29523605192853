import { API } from 'api/ApiConfig'
import { deleteApiHelper } from 'api/ApiHelper'
import Author from 'components/Author'
import DeleteModal from 'components/modal/DeleteModal'
import { AnimatePresence } from 'framer-motion'
import { useState } from 'react'
import { AiFillHeart, AiTwotoneDelete } from 'react-icons/ai'
import { BiSolidCommentDetail } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

interface ItemProps {
  post: any
  refresh: () => void
}

const PostItem = ({ post, refresh = () => {} }: ItemProps) => {
  const [showDelete, setShowDelete] = useState(false)

  const deletePost = () => {
    deleteApiHelper(
      `${API.deletePost}${post.id}`,
      (data) => {
        setShowDelete(false)
        toast(data)
        refresh()
      },
      (err) => {
        toast.error(err.toString())
      }
    ).catch((err) => {
      console.log(err)
    })
  }
  return (
    <div className="shadow-md rounded-md p-2 border-[#94CA65CC]">
      <AnimatePresence>
        {showDelete && (
          <DeleteModal
            description="Delete this post"
            handleCloseModal={() => setShowDelete(false)}
            handleDelete={deletePost}
          />
        )}
      </AnimatePresence>
      <div className="flex items-center justify-between">
        <Author author={post?.author} />
        <AiTwotoneDelete
          title="Delete Post"
          size={20}
          cursor="pointer"
          onClick={() => setShowDelete(true)}
          color="red"
        />
      </div>
      <Link to={`/community/${post?.id}`} onClick={() => {}}>
        {post.description && (
          <p className="text-sm py-2" dangerouslySetInnerHTML={{ __html: post.description }}></p>
        )}
        {post.image && <img src={post.image} className="my-2 w-[150px]" alt="" />}
        <div className="flex my-2 items-center gap-5">
          <div className="flex items-center gap-1">
            <AiFillHeart size={24} color="#94CA65" />
            <p>{post.likes?.length ?? 0}</p>
          </div>
          <div className="flex items-center gap-1">
            <BiSolidCommentDetail size={24} color="#94CA65" />
            <p>{post.commentCount ?? 0}</p>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default PostItem
