import { API } from 'api/ApiConfig'
import { deleteApiHelper } from 'api/ApiHelper'
import Author from 'components/Author'
import DeleteModal from 'components/modal/DeleteModal'
import { AnimatePresence } from 'framer-motion'
import { useState } from 'react'
import { AiTwotoneDelete } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

interface ItemProps {
  post?: any
  refresh?: () => void
}
const UserPostItem = ({ post, refresh = () => {} }: ItemProps) => {
  const [showDelete, setShowDelete] = useState(false)

  const deletePost = () => {
    deleteApiHelper(
      `${API.deletePost}${post.id}`,
      (data) => {
        setShowDelete(false)
        toast(data)
        refresh()
      },
      (err) => {
        toast.error(err.toString())
      }
    ).catch((err) => {
      console.log(err)
    })
  }

  return (
    <div className="border-t flex border-[#8080801A] py-3">
      <AnimatePresence>
        {showDelete && (
          <DeleteModal
            description="Delete this post"
            handleCloseModal={() => setShowDelete(false)}
            handleDelete={deletePost}
          />
        )}
      </AnimatePresence>
      <div className="flex-1">
        <Author author={post?.author} />
        <p
          className="text-sm mt-3"
          dangerouslySetInnerHTML={{ __html: post?.description?.split('\n').join('<br>') }}></p>
        {post?.image !== null && (
          <Link to={post?.image} target="_blank">
            <img src={post?.image} alt="" className="h-[100px]" />
          </Link>
        )}
        <div className="flex items-center gap-2 mt-3">
          <p className="text-xs font-bold">Likes: {post?.likes?.length}</p>
          <p className="text-xs font-bold">Comments: {post?.commentCount}</p>
        </div>
      </div>
      <AiTwotoneDelete
        title="Delete User"
        size={20}
        cursor="pointer"
        onClick={() => setShowDelete(true)}
        color="red"
      />
    </div>
  )
}

export default UserPostItem
