import { useMemo } from 'react'

interface StatusProps {
  status: string
}
const NoticationStatus = ({ status }: StatusProps) => {
  const color = useMemo(() => {
    if (status === 'Sent To Users') {
      return 'bg-[#94CA65]'
    }
    return 'bg-[#FFB522]'
  }, [status])
  return (
    <div className="w-[15%]">
      <p className={`rounded-2xl ${color} text-center px-2 py-1 w-fit text-xs text-white`}>
        {status}
      </p>
    </div>
  )
}

export default NoticationStatus
