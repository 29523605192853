import { API } from 'api/ApiConfig'
import { getApiHelper } from 'api/ApiHelper'
import { motion } from 'framer-motion'
import UserItem from 'pages/users/components/UserItem'
import { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { toast } from 'react-toastify'
import { dropIn } from 'utils/variants'

interface ItemProps {
  handleCloseModal: any
  title: string
}

const ListModal = ({ handleCloseModal, title }: ItemProps) => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [users, setUsers] = useState<any>()

  const getUsers = () => {
    setLoading(true)
    getApiHelper(
      `${API.getDashboardUsers}?page=${page}&size=20&type=${title}`,
      (data) => {
        setUsers(data)
        setLoading(false)
      },
      (err) => {
        toast.error(err?.toString())
        setLoading(false)
      }
    )
  }

  useEffect(() => {
    getUsers()
  }, [title, page])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={handleCloseModal}
      className="h-screen z-20 flex items-center justify-center w-screen fixed top-0 left-0 bg-[##8080802E]">
      <motion.div
        initial={{ opacity: 1 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="absolute top-0 left-0 right-0 bottom-0 bg-[#8080802E]"
        style={{
          backdropFilter: 'blur(2px)'
        }}></motion.div>
      <motion.div
        style={{
          backgroundColor: 'white'
        }}
        onClick={(e) => e.stopPropagation()}
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={dropIn}
        className="w-[80vw] max-h-[100vh] min-h-[300px] py-2 shadow-xl rounded-lg flex flex-col">
        <div className="flex-1">
          <h1 className="text-[22px] capitalize font-semibold text-center">{title} Users</h1>
          <div className="flex font-bold items-center px-2 gap-[10px] mt-5 py-3 bg-[#8080801A]">
            <h4 className="w-[12%] text-sm">Full Name</h4>
            <h4 className="w-[12%] text-sm">Username</h4>
            <h4 className="w-[22%] text-sm">Email</h4>
            <h4 className="w-[10%] text-sm">Role</h4>
            <h4 className="w-[8%] text-sm">Device</h4>
            <h4 className="w-[12%] text-sm">Created At</h4>
            <h4 className="w-[12%] text-sm">Last Active</h4>
            <h4 className="w-[12%] text-sm">Actions</h4>
          </div>
          <div className="flex-1 h-[60vh] overflow-y-scroll">
            {users?.content.map((item: any) => (
              <UserItem refresh={getUsers} key={item.id} user={item} />
            ))}
          </div>
        </div>
        <div className="flex font-bold justify-end items-center px-5 py-3 mt-2 bg-[#8080801A]">
          <ReactPaginate
            pageClassName="border border-transparent h-6 w-6 flex items-center justify-center rounded-full"
            previousClassName="mr-[20px] text-[20px] text-[#B1D147]"
            nextClassName="text-[#B1D147] ml-[20px] text-[20px]"
            containerClassName="flex items-center gap-2"
            activeClassName="bg-white"
            breakLabel="..."
            nextLabel=">"
            initialPage={parseInt(page.toString(), 10)}
            onPageChange={(selected) => {
              setPage(selected.selected)
            }}
            pageRangeDisplayed={5}
            pageCount={users?.totalPages ?? 0}
            previousLabel="<"
            renderOnZeroPageCount={null}
          />
        </div>
      </motion.div>
    </motion.div>
  )
}

export default ListModal
