import { API } from 'api/ApiConfig'
import { getApiHelper, postApiHelper } from 'api/ApiHelper'
import PageHeader from 'components/header/PageHeader'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import WorkoutPlanItem from './components/WorkoutPlanItem'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { RxDragHandleHorizontal } from 'react-icons/rx'

const WorkoutPlansPage = () => {
  const navigate = useNavigate()
  const [plans, setPlans] = useState<any>([])
  const toWorkoutPlan = () => {
    navigate('/workout-plans/add')
  }

  const getPlans = () => {
    getApiHelper(
      API.getWorkoutPlans,
      (data) => {
        setPlans(data?.sort((a: any, b: any) => a.sortIndex - b.sortIndex))
      },
      (err) => {
        toast.error(err.toString())
      }
    )
  }

  useEffect(() => {
    getPlans()
  }, [])

  useEffect(() => {
    if (plans.length) {
      postApiHelper(
        API.updatePlanPositions,
        {
          workoutPlans: plans.map((item: any, index: number) => ({
            ...item,
            planDays: [],
            sortIndex: index
          }))
        },
        () => {
          console.log('updated')
        },
        (err) => {
          console.log(err)
        }
      )
    }
  }, [plans])

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }
    const reorderedItems: any = Array.from(plans)
    const [removed] = reorderedItems.splice(result.source.index, 1)
    reorderedItems.splice(result.destination.index, 0, removed)
    setPlans(reorderedItems)
  }

  return (
    <div className="px-5">
      <PageHeader title="Workout Plans" hasAdd action={toWorkoutPlan} actionTitle="Add New" />
      <p className="text-sm">Workout plans offered in the application</p>
      <div className="mt-10">
        <div className="flex font-bold items-center px-2 gap-[10px] mt-5 py-3 bg-[#8080801A]">
          <RxDragHandleHorizontal />
          <h4 className="text-sm w-[12%]">Image</h4>
          <h4 className="text-sm w-[12%]">Name</h4>
          <h4 className="text-sm flex-1">Description</h4>
          <h4 className="w-[12%] text-sm">Create Date</h4>
          <h4 className="w-[12%] text-sm">Actions</h4>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {plans.map((item: any, index: number) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <div
                        style={{
                          ...provided.draggableProps.style
                        }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <WorkoutPlanItem plan={item} refresh={getPlans} key={item.id} />
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  )
}

export default WorkoutPlansPage
