import { API } from 'api/ApiConfig'
import { postApiHelper, uploadImageApiHelper } from 'api/ApiHelper'
import InnerPageHeader from 'components/header/InnerPageHeader'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

const AddTipsPage = () => {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [imageFile, setImageFile] = useState<any>(undefined)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const fileRef = useRef<any>()

  const handleTitleInput = (e: any) => {
    setTitle(e.target.value)
  }

  const handleDescriptionInput = (e: any) => {
    setDescription(e.target.value)
  }

  const handleFileChange = (e: any) => {
    setImageFile(e.target.files[0])
  }

  const addTip = async () => {
    if (!title || !description || !imageFile) {
      toast.error('Please fill all fields and add an image')
      return
    }
    setLoading(true)
    const image = await uploadImageApiHelper(imageFile)
    postApiHelper(
      API.addTip,
      { title, description, image },
      () => {
        setLoading(false)
        toast('Tip added succesfully')
        navigate(-1)
      },
      (err) => {
        setLoading(false)
        console.log(err)
      }
    )
  }

  return (
    <div className="px-5">
      <InnerPageHeader action={addTip} loading={loading} title="Add Tip" hasAction />
      <div className="mb-5"></div>
      <div className="flex gap-[10px] items-end">
        <img
          src={imageFile ? URL.createObjectURL(imageFile) : ''}
          className="h-[250px] object-contain w-[250px] bg-[#8080801A]"
          alt=""
        />
        <button
          onClick={() => {
            fileRef.current.click()
          }}
          className="text-white p-2 text-sm rounded-[50px]"
          style={{
            background: 'linear-gradient(90.5deg, #5BC0A1 -21.2%, #94CA65 52.32%, #B1D147 133.52%)'
          }}>
          Add Image
        </button>
        <input
          ref={fileRef}
          className="invisible w-[10px]"
          type="file"
          onChange={handleFileChange}
        />
      </div>
      <div className="mb-10"></div>
      <p className="font-bold text-sm mb-1">Title</p>
      <input
        value={title}
        onChange={handleTitleInput}
        type="text"
        className="bg-[#8080800D] w-full outline-none p-1"
        placeholder="Enter title"
      />
      <div className="mb-10"></div>
      <p className="font-bold text-sm mb-1">Description</p>
      <textarea
        value={description}
        onChange={handleDescriptionInput}
        name="details"
        placeholder="Enter details of the tips"
        id=""
        className="bg-[#8080800D] p-1 outline-none w-full min-h-[300px]"
      />
    </div>
  )
}

export default AddTipsPage
