import { API } from 'api/ApiConfig'
import { getApiHelper } from 'api/ApiHelper'
import PageHeader from 'components/header/PageHeader'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import NotificationItem from './components/NotificationItem'

const NotificationsPage = () => {
  const [notifications, setNotifications] = useState([])
  const navigate = useNavigate()
  const addNotification = () => {
    navigate('/notifications/add')
  }

  const getNotifications = () => {
    getApiHelper(
      API.getNotifications,
      (data) => {
        setNotifications(data)
        console.log(data)
      },
      (err) => {
        toast.error(err.toString())
      }
    ).catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    getNotifications()
  }, [])

  return (
    <div className="px-5">
      <PageHeader title="Notifications" hasAdd action={addNotification} />
      <p className="text-sm">This is the notifications page</p>
      <div className="flex items-center px-2 gap-[15px] mt-[10px] py-3 bg-[#8080801A]">
        <h4 className="text-sm w-[20%]">Title</h4>
        <h4 className="flex-1 text-sm">Description</h4>
        <h4 className="text-sm w-[15%]">Status</h4>
        <h4 className="text-sm w-[15%]">Broadcast date</h4>
        <h4 className="w-[20%] text-sm">Actions</h4>
      </div>
      {notifications.map((item: any) => (
        <NotificationItem reload={getNotifications} notification={item} key={item.id} />
      ))}
    </div>
  )
}

export default NotificationsPage
