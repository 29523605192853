import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import DashboardPage from './pages/dashboard/DashboardPage'
import AppLayout from './layouts/AppLayout'
import LoginPage from './pages/login/LoginPage'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import UserPage from 'pages/users/UserPage'
import TrophiesPage from 'pages/trophies/TrophiesPage'
import AppStatusPage from 'pages/appstatus/AppStatusPage'
import CommunityPage from 'pages/community/CommunityPage'
import ExercisePage from 'pages/exercises/ExercisePage'
import WorkoutPlansPage from 'pages/workoutplans/WorkoutPlanPage'
import TipsPage from 'pages/tips/TipsPage'
import AddTipsPage from 'pages/tips/AddTipsPage'
import EditTipsPage from 'pages/tips/EditTipsPage'
import NotificationsPage from 'pages/notifications/NotificationsPage'
import AddNotificationsPage from 'pages/notifications/AddNotificationsPage'
import CreateUserPage from 'pages/users/CreateUserPage'
import UserDetailPage from 'pages/users/UserDetailPage'
import AddExercisePage from 'pages/exercises/AddExercisePage'
import EditExercisePage from 'pages/exercises/EditExercisePage'
import AddWorkoutPlan from 'pages/workoutplans/AddWorkoutPlan'
import EditWorkoutPlan from 'pages/workoutplans/EditWorkoutPlan'
import WorkoutPlanDay from 'pages/workoutplans/inner/WorkoutPlanDay'
import ReportsPage from 'pages/reports/ReportsPage'
import AddTrophiesPage from 'pages/trophies/AddTrophiesPage'
import ReportActionPage from 'pages/reports/ReportActionPage'
import CommunityDetailPage from 'pages/community/CommunityDetailPage'
import EditTrophiesPage from 'pages/trophies/EditTrophiesPage'
import EditNotificationsPage from 'pages/notifications/EditNotificationsPage'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route path="/" element={<DashboardPage />} />
          <Route path="/users" element={<UserPage />} />
          <Route path="/users/:id" element={<UserDetailPage />} />
          <Route path="/users/create" element={<CreateUserPage />} />
          <Route path="/trophies" element={<TrophiesPage />} />
          <Route path="/trophies/:id" element={<EditTrophiesPage />} />
          <Route path="/trophies/add" element={<AddTrophiesPage />} />
          <Route path="/app-status" element={<AppStatusPage />} />
          <Route path="/community" element={<CommunityPage />} />
          <Route path="/community/:id" element={<CommunityDetailPage />} />
          <Route path="/exercises" element={<ExercisePage />} />
          <Route path="/exercises/add" element={<AddExercisePage />} />
          <Route path="/exercises/edit/:id" element={<EditExercisePage />} />
          <Route path="/notifications" element={<NotificationsPage />} />
          <Route path="/notifications/add" element={<AddNotificationsPage />} />
          <Route path="/notifications/edit/:id" element={<EditNotificationsPage />} />
          <Route path="/workout-plans" element={<WorkoutPlansPage />} />
          <Route path="/workout-plans/add" element={<AddWorkoutPlan />} />
          <Route path="/workout-plans/:id/day/add" element={<WorkoutPlanDay />} />
          <Route path="/workout-plans/:id/day/:id" element={<WorkoutPlanDay />} />
          <Route path="/workout-plans/edit/:id" element={<EditWorkoutPlan />} />
          <Route path="/tips" element={<TipsPage />} />
          <Route path="/tips/add" element={<AddTipsPage />} />
          <Route path="/tips/edit/:id" element={<EditTipsPage />} />
          <Route path="/reports" element={<ReportsPage />} />
          <Route path="/report/:id" element={<ReportActionPage />} />
        </Route>
        <Route path="/login" element={<LoginPage />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </BrowserRouter>
  )
}

export default App
