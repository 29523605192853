import { Link, useNavigate } from 'react-router-dom'
import MenuItem from './menuitem/MenuItem'
import { BiSolidDashboard } from 'react-icons/bi'
import { ImUsers } from 'react-icons/im'
import { SiInstatus } from 'react-icons/si'
import { CgGym } from 'react-icons/cg'
import { PiStrategyBold } from 'react-icons/pi'
import { AiFillBulb, AiTwotoneTrophy } from 'react-icons/ai'
import { BsFillPostcardFill } from 'react-icons/bs'
import { HiOutlineLogout } from 'react-icons/hi'
import { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import LogoutModal from 'components/modal/LogoutModal'
import Cookies from 'js-cookie'
import Gradient from 'rgt'
import { KEYS } from 'constants/KEYS'
import { IoIosNotifications } from 'react-icons/io'
import { MdReport } from 'react-icons/md'

const SideBar = () => {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)

  const close = () => {
    setShowModal(false)
  }

  const open = () => {
    setShowModal(true)
  }

  const signOutAction = () => {
    Cookies.remove(KEYS.token)
    navigate('/login')
  }

  return (
    <div className="hidden md:block py-5 px-6 max-w-[250px] w-[20vw] h-screen bg-[#F9F9F9]">
      <Link to="/">
        <h1 className="text-black text-center text-[20px] font-bold">
          <Gradient dir="left-to-right" from="#5BC0A1" to="#B1D147">
            BariLifestyle
          </Gradient>
        </h1>
      </Link>

      <AnimatePresence initial={false}>
        {showModal && <LogoutModal handleSignout={signOutAction} handleCloseModal={close} />}
      </AnimatePresence>

      <div className="mt-5">
        <MenuItem
          icon={<BiSolidDashboard />}
          active={location.pathname === '/'}
          label="Dashboard"
          path="/"
        />
        <MenuItem
          icon={<ImUsers />}
          active={location.pathname.includes('users')}
          label="Users"
          path="/users"
        />
        <p className="text-black/50 font-bold text-xs mt-5">Social</p>
        <MenuItem
          icon={<BsFillPostcardFill />}
          active={location.pathname.includes('community')}
          label="Community"
          path="/community"
        />
        <MenuItem
          icon={<AiFillBulb />}
          active={location.pathname.includes('tips')}
          label="Tips"
          path="/tips"
        />
        <MenuItem
          icon={<IoIosNotifications />}
          active={location.pathname.includes('notifications')}
          label="Notifications"
          path="/notifications"
        />
        <MenuItem
          icon={<MdReport />}
          active={location.pathname.includes('reports')}
          label="Reports"
          path="/reports"
        />
        <p className="text-black/50 font-bold text-xs mt-5">Achievements</p>
        <MenuItem
          icon={<AiTwotoneTrophy />}
          active={location.pathname.includes('trophies')}
          label="Trophies"
          path="/trophies"
        />
        <p className="text-black/50 font-bold text-xs mt-5">Workouts</p>
        <MenuItem
          icon={<CgGym />}
          active={location.pathname.includes('exercises')}
          label="Exercises"
          path="/exercises"
        />
        <MenuItem
          icon={<PiStrategyBold />}
          active={location.pathname.includes('workout-plans')}
          label="Workout Plans"
          path="/workout-plans"
        />
        <p className="text-black/50 font-bold text-xs mt-5">Others</p>
        <MenuItem
          icon={<SiInstatus />}
          active={location.pathname.includes('app-status')}
          label="App Status"
          path="/app-status"
        />
        <div
          onClick={open}
          className="flex cursor-pointer items-center gap-2 px-4 py-2 my-3 rounded-lg"
          style={{
            backgroundColor: '#F9F9F9',
            color: 'black'
          }}>
          <HiOutlineLogout />
          <p>Logout</p>
        </div>
      </div>
    </div>
  )
}

export default SideBar
