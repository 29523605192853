import { API } from 'api/ApiConfig'
import { postApiHelper } from 'api/ApiHelper'
import InnerPageHeader from 'components/header/InnerPageHeader'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

function validateEmail(email: string) {
  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  return email.match(validRegex)
}

const CreateUserPage = () => {
  const [fullName, setFullname] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confPassword, setConfPassword] = useState('')
  const [username, setUserName] = useState('')
  const [role, setRole] = useState('REGULAR')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const saveUser = async () => {
    if (!fullName || !email || !password || !confPassword || !username) {
      toast.error('Please fill all the fields')
      return
    }
    if (!validateEmail(email)) {
      toast.error('Please enter a valid email address')
      return
    }
    if (password !== confPassword) {
      toast.error('The passwords do not match')
      return
    }
    setLoading(true)
    await postApiHelper(
      API.createUser,
      { username, fullName, email, password, role },
      (data) => {
        // go to the user detail page
        navigate(`/users/${data.id}`)
        setLoading(false)
        console.log(data)
      },
      (err) => {
        setLoading(false)
        toast.error(err.toString())
      }
    ).catch((err) => console.log(err.toString()))
  }
  return (
    <div className="px-5">
      <InnerPageHeader loading={loading} title="Create New User" hasAction action={saveUser} />
      <div className="mt-10">
        <p className="font-bold text-sm mb-1">Full Name</p>
        <input
          value={fullName}
          onChange={(e) => setFullname(e.target.value)}
          type="text"
          className="bg-[#8080800D] w-full outline-none p-1"
          placeholder="John Doe"
        />
        <p className="font-bold text-sm mt-5 mb-1">Username</p>
        <input
          value={username}
          onChange={(e) => setUserName(e.target.value)}
          type="text"
          className="bg-[#8080800D] lowercase w-full outline-none p-1"
          placeholder="John Doe"
        />
        <p className="font-bold mt-5 text-sm mb-1">Email</p>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          className="bg-[#8080800D] w-full outline-none p-1"
          placeholder="John Doe"
        />
        <p className="font-bold mt-5 text-sm mb-1">Password</p>
        <input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          className="bg-[#8080800D] w-full outline-none p-1"
          placeholder="John Doe"
        />
        <p className="font-bold mt-5 text-sm mb-1">Re-Enter Password</p>
        <input
          value={confPassword}
          onChange={(e) => setConfPassword(e.target.value)}
          type="password"
          className="bg-[#8080800D] w-full outline-none p-1"
          placeholder="John Doe"
        />
        <p className="font-bold mt-5 text-sm mb-1">User Type</p>
        <select
          onChange={(e) => setRole(e.target.value)}
          className="bg-[#8080800D] w-full outline-none p-1"
          name=""
          id="">
          <option value="REGULAR">Regular</option>
          <option value="PREMIUM">Premium</option>
          <option value="ADMIN">Admin</option>
          <option value="SUBADMIN">Sub Admin</option>
        </select>
      </div>
    </div>
  )
}

export default CreateUserPage
