import { API } from 'api/ApiConfig'
import { deleteApiHelper } from 'api/ApiHelper'
import DeleteModal from 'components/modal/DeleteModal'
import { AnimatePresence } from 'framer-motion'
import { useState } from 'react'
import { AiTwotoneDelete } from 'react-icons/ai'
import { RxDragHandleHorizontal } from 'react-icons/rx'

interface ItemProps {
  selected: boolean
  select: any
  item: any
  exercise?: any
  removeFromList: (id: string) => any
}

const DayExerciseItem = ({ item, exercise, removeFromList, select, selected }: ItemProps) => {
  const [showDelete, setShowDelete] = useState(false)

  const deleteItem = () => {
    deleteApiHelper(
      `${API.deleteDayExercise}${item.id}`,
      () => {
        setShowDelete(false)
        removeFromList(item.id)
        // remove it from the selected list
      },
      (err) => {
        console.log(err)
      }
    )
  }

  return (
    <div
      onClick={select}
      style={{ backgroundColor: selected ? '#5BC0A1CC' : 'transparent' }}
      className="flex border-b border-[#8080802E] items-center justify-between">
      <AnimatePresence>
        {showDelete && (
          <DeleteModal
            description="Delete this exercise from the plan?"
            handleCloseModal={() => setShowDelete(false)}
            handleDelete={deleteItem}
          />
        )}
      </AnimatePresence>
      <div className="flex items-center gap-5">
        <RxDragHandleHorizontal />
        <div className="flex items-center gap-2">
          <img className="w-[50px] h-[50px] object-contain" src={exercise?.image} alt="" />
          <div>
            <h4 className="font-semibold text-sm">{exercise?.name}</h4>
            <p className="text-xs">
              {item.duration} {item.type === 'reps' ? 'Reps' : 'seconds'}
            </p>
          </div>
        </div>
      </div>

      <AiTwotoneDelete
        title="Delete User"
        size={20}
        cursor="pointer"
        onClick={() => setShowDelete(true)}
        color="red"
      />
    </div>
  )
}

export default DayExerciseItem
