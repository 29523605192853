import { API } from 'api/ApiConfig'
import { getApiHelper } from 'api/ApiHelper'
import PageHeader from 'components/header/PageHeader'
import { IoSearchOutline } from 'react-icons/io5'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import UserItem from './components/UserItem'
import { useLocation, useNavigate } from 'react-router'
import ReactPaginate from 'react-paginate'
import { BiExport } from 'react-icons/bi'
import { AnimatePresence } from 'framer-motion'
import ExportModal from './components/ExportModal'
import { CSVLink } from 'react-csv'
import TableHeaderItem from './components/TableHeaderItem'

const HEADERS = [
  { label: 'User ID', key: 'id' },
  { label: 'Full Name', key: 'fullName' },
  { label: 'Username', key: 'username' },
  { label: 'Email', key: 'email' },
  { label: 'Gender', key: 'gender' },
  { label: 'Banned', key: 'banned' },
  { label: 'Role', key: 'role' },
  { label: 'Weight', key: 'userInformation.weight' },
  { label: 'Wrist', key: 'userInformation.wrist' },
  { label: 'Height', key: 'userInformation.height' },
  { label: 'Hips', key: 'userInformation.hips' },
  { label: 'Biceps', key: 'userInformation.biceps' },
  { label: 'Chest', key: 'userInformation.chest' },
  { label: 'Daily Steps', key: 'userInformation.dailySteps' },
  { label: 'Daily Water', key: 'userInformation.dailyWater' },
  { label: 'Daily Calories', key: 'userInformation.dailyCalories' }
]

const UserPage = () => {
  const csvRef = useRef<any>(null)
  const [showExportModal, setShowExportModal] = useState(false)
  const [users, setUsers] = useState<any>()
  const [perPage, setPerPage] = useState('10')
  const [search, setSearch] = useState('')
  const [sortBy, setSortBy] = useState('createdAt')
  const [sortDirection, setSortDirection] = useState('DESC')
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const [page, setPage] = useState(searchParams.get('page') ?? 0)

  const [allUsers, setAllUsers] = useState<any>([])

  const navigate = useNavigate()

  const getUsers = () => {
    getApiHelper(
      `${API.getUsers}?page=${page}&size=${perPage}&search=${search}&sortBy=${sortBy}&sortDirection=${sortDirection}`,
      (data) => {
        setUsers(data)
      },
      (err) => {
        toast.error(err.toString())
      }
    )
  }

  const getAllUsers = () => {
    getApiHelper(
      `${API.getUsers}?export=true`,
      (data) => {
        if (!data.length) {
          setAllUsers([data])
        } else {
          setAllUsers(data)
        }
      },
      (err) => {
        console.log(err)
      }
    )
  }

  useEffect(() => {
    getAllUsers()
    if (page !== 0) {
      setPage(parseInt(page.toString(), 10) - 1)
    }
  }, [])

  useEffect(() => {
    getUsers()
  }, [perPage, search, sortBy, sortDirection, page])

  const toAddUser = () => {
    navigate('/users/create')
  }

  const exportUsers = () => {
    setShowExportModal(false)
    csvRef.current.link.click()
  }

  const toggleSort = (sort: string, sortD: string) => {
    setSortBy(sort)
    setSortDirection(sortD)
  }

  return (
    <div className="px-5">
      <AnimatePresence>
        {showExportModal && (
          <ExportModal
            description="Export all the users in the platform to CSV"
            handleCloseModal={() => setShowExportModal(false)}
            handleExport={exportUsers}
          />
          // <ExportUsersModal handleCloseModal={() => setShowExportModal(false)} />
        )}
      </AnimatePresence>
      <PageHeader title="Users" hasAdd actionTitle="Create User" action={toAddUser} />
      <p className="text-sm">View all users of the BariLifetsyle platform</p>
      <div className="flex items-center w-full gap-3 mt-10">
        <div className="border-[#8080801A] border-2 flex-1 rounded-sm h-[45px] flex items-center">
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="search"
            className="py-3 px-2 flex-1 outline-none text-sm"
            placeholder="Search for a user by email or name"
          />
          <div className="h-full bg-[#8080801A] flex items-center justify-center w-[5%]">
            <IoSearchOutline />
          </div>
        </div>
        <div
          title="Export all users"
          onClick={() => setShowExportModal(true)}
          className="cursor-pointer flex h-[45px] w-[60px] rounded-md items-center justify-center bg-[#B1D147]">
          <BiExport size={24} color="white" />
        </div>
      </div>
      <div className="flex cursor-pointer font-bold items-center px-2 gap-[10px] mt-5 py-3 bg-[#8080801A]">
        <TableHeaderItem
          label="Full Name"
          onClick={(direction: string) => toggleSort('fullName', direction)}
          width="w-[12%]"
        />
        <TableHeaderItem
          label="Username"
          onClick={(direction: string) => toggleSort('username', direction)}
          width="w-[12%]"
        />
        <TableHeaderItem
          label="Email"
          onClick={(direction: string) => toggleSort('email', direction)}
          width="w-[22%]"
        />
        <TableHeaderItem
          label="User Type"
          onClick={(direction: string) => toggleSort('role', direction)}
          width="w-[10%]"
        />
        <h4 onClick={() => setSortBy('device')} className="w-[8%] text-sm">
          Device
        </h4>
        <TableHeaderItem
          label="Date Joined"
          onClick={(direction: string) => toggleSort('createdAt', direction)}
          width="w-[12%]"
        />
        <TableHeaderItem
          label="Last Online"
          onClick={(direction: string) => toggleSort('lastActive', direction)}
          width="w-[12%]"
        />
        <h4 className="w-[12%] text-sm">Actions</h4>
      </div>
      <div>
        {users?.content.map((item: any) => (
          <UserItem refresh={getUsers} key={item.id} user={item} />
        ))}
      </div>

      <div className="flex font-bold justify-between items-center px-2 py-3 mt-2 bg-[#8080801A]">
        <div className="flex items-center gap-2">
          <p className="text-xs">Items per page</p>
          <select
            value={perPage}
            onChange={(e) => {
              setPerPage(e.target.value)
            }}
            className="bg-transparent outline-none"
            name=""
            id="">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <ReactPaginate
          pageClassName="border border-transparent h-6 w-6 flex items-center justify-center rounded-full"
          previousClassName="mr-[20px] text-[20px] text-[#B1D147]"
          nextClassName="text-[#B1D147] ml-[20px] text-[20px]"
          containerClassName="flex items-center gap-2"
          activeClassName="bg-white"
          breakLabel="..."
          nextLabel=">"
          initialPage={parseInt(page.toString(), 10)}
          onPageChange={(selected) => {
            setPage(selected.selected)
            if (selected.selected === 0) {
              navigate(``)
            } else {
              navigate(`?page=${selected.selected}`)
            }
          }}
          pageRangeDisplayed={5}
          pageCount={users?.totalPages ?? 0}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      </div>
      <CSVLink
        ref={csvRef}
        className="hidden"
        filename="BariLifestyle_Users"
        headers={HEADERS}
        data={allUsers}>
        Download me
      </CSVLink>
    </div>
  )
}

export default UserPage
