import { API } from 'api/ApiConfig'
import { deleteApiHelper, postApiHelper } from 'api/ApiHelper'
import DeleteModal from 'components/modal/DeleteModal'
import { AnimatePresence } from 'framer-motion'
import moment from 'moment'
import { useState } from 'react'
import { AiTwotoneCopy, AiTwotoneDelete, AiTwotoneEdit } from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import CloneModal from './CloneModal'

interface ItemProps {
  exercise: any
  refresh: () => void
}
const ExerciseItem = ({ exercise, refresh = () => {} }: ItemProps) => {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [cloneModal, setCloneModal] = useState(false)
  const [cloning, setCloning] = useState(false)

  const deleteExercise = () => {
    deleteApiHelper(
      `${API.deleteExercise}${exercise.id}`,
      (data) => {
        toast(data)
        refresh()
      },
      (err) => {
        toast.error(err.toString())
      }
    )
  }

  const cloneExercise = () => {
    setCloning(true)
    postApiHelper(
      `${API.cloneExercise}${exercise.id}`,
      {},
      (data) => {
        setCloning(false)
        toast('Exercise cloned!')
        navigate(`/exercises/edit/${data.id}`)
      },
      (err) => {
        setCloning(false)
        toast.error(err.toString())
      }
    )
  }

  return (
    <div className="flex px-2 gap-[10px] py-3">
      <AnimatePresence>
        {showModal && (
          <DeleteModal
            description="This will remove it from all workout programs, this cannot be changed!"
            handleCloseModal={() => setShowModal(false)}
            handleDelete={deleteExercise}
          />
        )}
        {cloneModal && (
          <CloneModal
            loading={cloning}
            handleClone={cloneExercise}
            description="Make a copy of this exercise"
            handleCloseModal={() => setCloneModal(false)}
          />
        )}
      </AnimatePresence>
      <div className="w-[20%] flex justify-center">
        <img src={exercise.image} className="object-contain" alt="" />
      </div>
      <p className="text-sm font-semibold w-[12%]">{exercise.name}</p>
      <p
        className="text-sm flex-1"
        dangerouslySetInnerHTML={{
          __html: exercise.description?.split('\n').join('<br> <br>')
        }}></p>
      <p className="w-[12%] text-sm">{moment(exercise.createdAt).format('DD-MMM-YYYY')}</p>
      <div className="flex gap-[10px] w-[12%]">
        <Link to={`/exercises/edit/${exercise.id}`}>
          <AiTwotoneEdit size={20} cursor="pointer" color="#94CA65" />
        </Link>
        <AiTwotoneCopy
          className="text-blue-400"
          size={20}
          cursor="pointer"
          onClick={() => setCloneModal(true)}
        />
        <AiTwotoneDelete
          size={20}
          cursor="pointer"
          onClick={() => setShowModal(true)}
          color="red"
        />
      </div>
    </div>
  )
}

export default ExerciseItem
