import ListModal from 'components/modal/ListModal'
import { AnimatePresence } from 'framer-motion'
import { useMemo, useState } from 'react'
import { LuDollarSign } from 'react-icons/lu'

interface ItemProps {
  data: any
}
const MonthlyEarning = ({ data = { monthly: 0, yearly: 0, threeMonths: 0 } }: ItemProps) => {
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const monthlyPercent = useMemo(() => {
    if (data) {
      const total = parseInt(data.monthly) + parseInt(data.threeMonths) + parseInt(data.yearly)
      return parseInt(data.monthly) / total
    }
    return 1
  }, [data])

  const threeMonthsPercent = useMemo(() => {
    if (data) {
      const total = parseInt(data.monthly) + parseInt(data.threeMonths) + parseInt(data.yearly)
      return parseInt(data.threeMonths) / total
    }
    return 1
  }, [data])
  const yearlyPercent = useMemo(() => {
    if (data) {
      const total = parseInt(data.monthly) + parseInt(data.threeMonths) + parseInt(data.yearly)
      return parseInt(data.yearly) / total
    }
    return 1
  }, [data])

  const handleShowData = (type: string) => {
    setShowModal(true)
    setModalTitle(`${type}`)
  }

  return (
    <div
      className="flex-1 rounded-lg p-5"
      style={{
        boxShadow: '0px 0px 100px #8080804D'
      }}>
      <AnimatePresence initial={false}>
        {showModal && <ListModal title={modalTitle} handleCloseModal={() => setShowModal(false)} />}
      </AnimatePresence>
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-[30px]">
            {parseInt(data.monthly) + parseInt(data.threeMonths) + parseInt(data.yearly)}
          </h1>
          <p>All Subscriptions</p>
        </div>
        <LuDollarSign size={34} color="#94CA65" />
      </div>

      <div className="flex-1 mt-5">
        <div className="flex items-end gap-[10px]">
          <div className="flex flex-col items-center justify-center">
            <div
              onClick={() => handleShowData('monthly')}
              style={{
                height: 70 * monthlyPercent
              }}
              className={`rounded-md w-[25px] hover:opacity-50 cursor-pointer bg-red-500`}
            />
            <p className="text-sm">Monthly</p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div
              onClick={() => handleShowData('three months')}
              style={{
                height: 70 * threeMonthsPercent
              }}
              className={`rounded-md w-[25px] hover:opacity-50 cursor-pointer bg-green-500`}
            />
            <p className="text-sm">3 Months</p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div
              onClick={() => handleShowData('yearly')}
              style={{
                height: 70 * yearlyPercent
              }}
              className="rounded-md w-[25px] hover:opacity-50 cursor-pointer bg-purple-500"
            />
            <p className="text-sm">Yearly</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MonthlyEarning
