import { API } from 'api/ApiConfig'
import { getApiHelper, postApiHelper, uploadImageApiHelper } from 'api/ApiHelper'
import ImageUploader from 'components/Image/ImageUploader'
import InnerPageHeader from 'components/header/InnerPageHeader'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import DayItem from './components/DayItem'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'

const EditWorkoutPlan = () => {
  const fileRef = useRef<any>()
  const [days, setDays] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState('')
  const [id, setId] = useState('')
  const [image, setImage] = useState('')
  const [imageFile, setImageFile] = useState<any>(undefined)
  const [description, setDescription] = useState('')
  const [sortIndex, setSortIndex] = useState(0)

  const saveWorkout = async () => {
    if (!title) {
      toast.error('Please enter a title!')
      return
    }
    if (!description) {
      toast.error('Please enter the description!')
      return
    }
    setLoading(true)
    let imageURL = image
    if (imageFile) {
      imageURL = await uploadImageApiHelper(imageFile)
    }
    const body: any = { sortIndex, name: title, description, image: imageURL, id }
    if (days.length) {
      body.planDays = days.map((item: any, index: number) => {
        const planDay = {
          ...item,
          workoutPlan: { id: location.pathname.split('/')[3] },
          sortIndex: index
        }
        delete planDay.dayExercises
        return planDay
      })
    }
    postApiHelper(
      API.addWorkoutPlan,
      body,
      () => {
        setLoading(false)
        toast('Workout updated successfully!')
      },
      (err) => {
        toast.error(err.toString())
        setLoading(false)
      }
    )
  }

  const handleFileChange = (e: any) => {
    setImageFile(e.target.files[0])
    setImage(URL.createObjectURL(e.target.files[0]))
  }

  const getWorkoutPlan = () => {
    getApiHelper(
      `${API.getWorkoutPlan}${location.pathname.split('/')[3]}`,
      (data) => {
        if (data) {
          console.log(data)
          setDays(data.planDays?.sort((a: any, b: any) => a.sortIndex - b.sortIndex))
          setId(data.id)
          setTitle(data.name)
          setDescription(data.description)
          setImage(data.image)
          setSortIndex(data.sortIndex)
        }
      },
      (err) => {
        toast.error(err.toString())
      }
    )
  }

  useEffect(() => {
    getWorkoutPlan()
  }, [])

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }
    const reorderedItems: any = Array.from(days)
    const [removed] = reorderedItems.splice(result.source.index, 1)
    reorderedItems.splice(result.destination.index, 0, removed)
    setDays(reorderedItems)
  }

  return (
    <div className="px-5">
      <InnerPageHeader hasAction loading={loading} action={saveWorkout} title="Edit Workout Plan" />
      <div className="mt-10">
        <ImageUploader
          imageSize="small"
          imageFile={image}
          fileRef={fileRef}
          handleFileChange={handleFileChange}
        />
        <div className="mt-5"></div>
        <p className="font-bold text-sm mb-1">Title</p>
        <input
          value={title}
          onChange={(e: any) => setTitle(e.target.value)}
          type="text"
          className="bg-[#8080800D] w-full outline-none p-1"
          placeholder="Enter title"
        />
        <div className="mt-5"></div>
        <p className="font-bold text-sm mb-1">Description</p>
        <textarea
          value={description}
          onChange={(e: any) => setDescription(e.target.value)}
          className="bg-[#8080800D] w-full min-h-[100px] outline-none p-1"
          placeholder="Enter title"
        />
      </div>
      <div className="mt-5">
        <div className="flex items-center gap-10">
          <div>
            <h1>Workout Days</h1>
            <p className="text-sm">Add daily exercises to this plan</p>
          </div>
          <Link to={`/workout-plans/${location.pathname.split('/')[3]}/day/add`}>
            <button
              className="text-sm text-white h-[40px] rounded-[10px] px-1 min-w-[100px]"
              style={{
                background:
                  'linear-gradient(90.5deg, #5BC0A1 -21.2%, #94CA65 52.32%, #B1D147 133.52%)'
              }}>
              Add
            </button>
          </Link>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div className="w-[30%]" {...provided.droppableProps} ref={provided.innerRef}>
                {days.map((item: any, index: number) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <div
                        style={{
                          ...provided.draggableProps.style
                        }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <DayItem refresh={getWorkoutPlan} key={item.id} day={item} />
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className="h-[50px]"></div>
    </div>
  )
}

export default EditWorkoutPlan
