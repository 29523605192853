import { API } from 'api/ApiConfig'
import { deleteApiHelper } from 'api/ApiHelper'
import Author from 'components/Author'
import DeleteModal from 'components/modal/DeleteModal'
import { AnimatePresence } from 'framer-motion'
import { useState } from 'react'
import { AiTwotoneDelete } from 'react-icons/ai'
import { toast } from 'react-toastify'

interface ItemProps {
  comment: any
  refresh: () => any
}

const CommentItem = ({ comment, refresh }: ItemProps) => {
  const [showModal, setShowModal] = useState(false)
  const deleteComment = () => {
    deleteApiHelper(
      `${API.deleteComment}${comment?.id}`,
      (data) => {
        console.log(data)
        refresh()
      },
      (err) => {
        toast.error(err?.toString())
      }
    ).catch((err) => {
      console.log(err)
    })
  }
  return (
    <div
      className="flex items-center justify-between"
      style={{
        padding: '10px 0px',
        borderRadius: 10,
        marginLeft: 20,
        marginTop: 10
      }}>
      <AnimatePresence>
        {showModal && (
          <DeleteModal
            description="Delete this comment"
            handleCloseModal={() => setShowModal(false)}
            handleDelete={deleteComment}
          />
        )}
      </AnimatePresence>
      <div>
        <Author author={comment?.author} />
        <p className="mt-2 text-sm">{comment?.description}</p>
      </div>
      <AiTwotoneDelete
        title="Delete Comment"
        size={20}
        cursor="pointer"
        onClick={() => setShowModal(true)}
        color="red"
      />
    </div>
  )
}

export default CommentItem
