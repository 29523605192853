import { API } from 'api/ApiConfig'
import { getApiHelper } from 'api/ApiHelper'
import PageHeader from 'components/header/PageHeader'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import TipItem from './components/TipItem'
import ReactPaginate from 'react-paginate'

const TipsPage = () => {
  const navigate = useNavigate()
  const [tips, setTips] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [perPage, setPerPage] = useState('10')
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const [page, setPage] = useState(searchParams.get('page') ?? 0)
  const toAdd = () => {
    navigate('/tips/add')
  }

  const getTips = () => {
    setLoading(true)
    getApiHelper(
      `${API.getTips}?page=${page}&size=${perPage}`,
      (data) => {
        setTips(data)
        setLoading(false)
      },
      (err) => {
        toast.error(err.toString())
        setLoading(false)
      }
    )
  }

  useEffect(() => {
    getTips()
  }, [page, perPage])

  return (
    <div className="px-5">
      <PageHeader action={toAdd} hasAdd title="Tips" />
      <div className="flex font-bold items-center px-2 gap-[10px] mt-[10px] py-3 bg-[#8080801A]">
        <p className="w-[45px]"></p>
        <h4 className="text-sm w-[30%]">Title</h4>
        <h4 className="flex-1 text-sm">Like count</h4>
        <h4 className="flex-1 text-sm">Date Created</h4>
        <h4 className="flex-1 text-sm">Created By</h4>
        <h4 className="flex-1 text-sm">Actions</h4>
      </div>
      {tips?.content.map((item: any) => (
        <TipItem refresh={getTips} key={item.id} tip={item} />
      ))}
      <div className="flex font-bold justify-between items-center px-2 py-3 mt-2 bg-[#8080801A]">
        <div className="flex items-center gap-2">
          <p className="text-xs">Items per page</p>
          <select
            value={perPage}
            onChange={(e) => {
              setPerPage(e.target.value)
            }}
            className="bg-transparent outline-none"
            name=""
            id="">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <ReactPaginate
          pageClassName="border border-transparent h-6 w-6 flex items-center justify-center rounded-full"
          previousClassName="mr-[20px] text-[20px] text-[#B1D147]"
          nextClassName="text-[#B1D147] ml-[20px] text-[20px]"
          containerClassName="flex items-center gap-2"
          activeClassName="bg-white"
          breakLabel="..."
          nextLabel=">"
          initialPage={parseInt(page.toString(), 10)}
          onPageChange={(selected) => {
            setPage(selected.selected)
            if (selected.selected === 0) {
              navigate(``)
            } else {
              navigate(`?page=${selected.selected}`)
            }
          }}
          pageRangeDisplayed={5}
          pageCount={tips?.totalPages ?? 0}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  )
}

export default TipsPage
