import { useState } from 'react'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'
import { toast } from 'react-toastify'

interface ItemProps {
  label: string
  width: string
  onClick: (direction: string) => any
}
const TableHeaderItem = ({ onClick, width, label }: ItemProps) => {
  const [direction, setDirection] = useState('DESC')

  const sortClick = () => {
    if (direction === 'DESC') {
      onClick('DESC')
      setDirection('ASC')
    } else {
      onClick('ASC')
      setDirection('DESC')
    }
  }

  return (
    <div onClick={sortClick} className={`${width} cursor-pointer flex items-center gap-2`}>
      <h4 className={`text-sm`}>{label}</h4>
      <div className="flex items-center">
        <FaCaretDown />
        <FaCaretUp />
      </div>
    </div>
  )
}
export default TableHeaderItem
