import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'

interface ItemProps {
  workoutVal?: any
  cancel: any
  selectedDayExercise: number
  exercises?: any
  selectExercise: (e: any) => void
}
const AddExerciseSection = ({
  exercises,
  workoutVal,
  selectExercise,
  selectedDayExercise,
  cancel
}: ItemProps) => {
  const [workout, setWorkout] = useState(
    workoutVal ?? {
      id: uuidv4(),
      exerciseId: exercises[0]?.id ?? '',
      type: 'reps',
      exercise: { id: '' },
      duration: '',
      restDuration: '',
      sortIndex: exercises.length
    }
  )

  useEffect(() => {
    setWorkout(
      workoutVal ?? {
        id: uuidv4(),
        exerciseId: exercises[0]?.id ?? '',
        type: 'reps',
        exercise: { id: '' },
        duration: '',
        restDuration: '',
        sortIndex: exercises.length
      }
    )
  }, [workoutVal])

  const setWorkoutInfo = (key: string, value: string) => {
    setWorkout((prev: any) => {
      const newObj: any = {}
      newObj[key] = value
      const newVal = Object.assign({}, prev, newObj)
      return newVal
    })
  }

  const addExercise = () => {
    if (!workout.duration || !workout.restDuration) {
      toast.error('Please fill all the fields')
      return
    }
    if (!workout.exerciseId) {
      workout.exerciseId = exercises[0].id
    }
    workout.exercise = { id: workout.exerciseId }
    selectExercise(workout)
    setWorkout({
      id: uuidv4(),
      exerciseId: exercises[0].id,
      exercise: { id: '' },
      type: 'reps',
      duration: '',
      restDuration: ''
    })
  }

  return (
    <div className="bg-white flex-1 p-2 shadow-md mt-5">
      {selectedDayExercise > -1 && (
        <div className="my-2 flex items-center justify-between">
          <h3 className="font-semibold">Editing Exercise</h3>
          <button onClick={cancel} className="text-red-400 text-sm font-bold">
            Cancel
          </button>
        </div>
      )}

      <p className="text-xs font-bold">Select Exercise</p>
      <select
        value={workout.exerciseId}
        onChange={(e) => setWorkoutInfo('exerciseId', e.target.value)}
        className="mt-1 text-sm px-2"
        name=""
        id="">
        {exercises.map((item: any) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
      <div className="mt-5"></div>
      <p className="text-xs font-bold">Workout Type</p>
      <div className="flex items-center gap-2">
        <input
          type="radio"
          checked={workout.type === 'reps'}
          onChange={(e) => setWorkoutInfo('type', e.target.value)}
          name="reps"
          value="reps"
          id=""
        />
        <p className="text-xs">Reps Workout</p>
      </div>
      <div className="flex items-center gap-2">
        <input
          type="radio"
          onChange={(e) => setWorkoutInfo('type', e.target.value)}
          checked={workout.type === 'timed'}
          name="reps"
          value="timed"
          id=""
        />
        <p className="text-xs">Timed Workout</p>
      </div>
      <div className="mt-5"></div>
      <p className="text-xs font-bold">Workout {workout.type === 'reps' ? 'reps' : 'duration'}</p>
      <input
        value={workout.duration}
        onChange={(e) => setWorkoutInfo('duration', e.target.value)}
        className="text-sm mt-1 h-[40px] w-full px-2 bg-[#8080800D]"
        type="number"
        placeholder={`Enter the workout ${
          workout.type === 'reps' ? 'rep count' : 'duration in seconds'
        }`}
      />
      <div className="mt-5"></div>
      <p className="text-xs font-bold">Rest duration</p>
      <input
        value={workout.restDuration}
        onChange={(e) => setWorkoutInfo('restDuration', e.target.value)}
        className="text-sm mt-1 h-[40px] w-full px-2 bg-[#8080800D]"
        type="number"
        placeholder="Enter the rest duration in seconds"
      />
      <div className="mt-5"></div>
      <button
        onClick={addExercise}
        className="text-sm text-white h-[40px] rounded-[10px] px-1 min-w-[100px]"
        style={{
          background: 'linear-gradient(90.5deg, #5BC0A1 -21.2%, #94CA65 52.32%, #B1D147 133.52%)'
        }}>
        {selectedDayExercise > -1 ? 'Save' : 'Add'}
      </button>
    </div>
  )
}

export default AddExerciseSection
