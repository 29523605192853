import { API } from 'api/ApiConfig'
import { getApiHelper } from 'api/ApiHelper'
import PageHeader from 'components/header/PageHeader'
import TableFooter from 'components/table/TableFooter'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import ExerciseItem from './components/ExerciseItem'
import { IoSearchOutline } from 'react-icons/io5'
import TableHeaderItem from 'pages/users/components/TableHeaderItem'

const ExercisePage = () => {
  const [exercises, setExercises] = useState<any>()
  const [pageSize, setPageSize] = useState('10')
  const navigate = useNavigate()
  const location = useLocation()
  const [search, setSearch] = useState('')
  const searchParams = new URLSearchParams(location.search)
  const [page, setPage] = useState(searchParams.get('page') ?? 0)

  const [sortBy, setSortBy] = useState('name')
  const [sortDirection, setSortDirection] = useState('DESC')
  const toggleSort = (sort: string, sortD: string) => {
    setSortBy(sort)
    setSortDirection(sortD)
    setPage(0)
  }

  const addExercise = () => {
    navigate('/exercises/add')
  }

  const getExercises = () => {
    getApiHelper(
      `${API.getExercises}?page=${page}&size=${pageSize}&search=${search}&sortBy=${sortBy}&sortDirection=${sortDirection}`,
      (data) => {
        console.log(data)
        setExercises(data)
      },
      (err) => {
        toast.error(err.toString())
      }
    )
  }

  useEffect(() => {
    getExercises()
  }, [pageSize, page, search, sortBy, sortDirection])

  return (
    <div className="px-5">
      <PageHeader title="Exercises" hasAdd actionTitle="Add Exercise" action={addExercise} />
      <p className="text-sm">All exercises in the offered</p>
      <div className="mt-5">
        <div className="flex items-center w-full gap-3 mt-10">
          <div className="border-[#8080801A] border-2 flex-1 rounded-sm h-[45px] flex items-center">
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              type="text"
              className="py-3 px-2 flex-1 outline-none text-sm"
              placeholder="Search for an exercise name"
            />
            <div className="h-full bg-[#8080801A] flex items-center justify-center w-[5%]">
              <IoSearchOutline />
            </div>
          </div>
        </div>
        <div className="flex font-bold items-center px-2 gap-[10px] mt-5 py-3 bg-[#8080801A]">
          <h4 className="text-sm w-[20%]">Image</h4>
          <TableHeaderItem
            label="Name"
            onClick={(direction: string) => toggleSort('name', direction)}
            width="w-[12%]"
          />
          <h4 className="text-sm flex-1">Description</h4>
          <h4 className="w-[12%] text-sm">Create Date</h4>
          <h4 className="w-[12%] text-sm">Actions</h4>
        </div>
        {exercises?.content?.map((item: any) => (
          <ExerciseItem refresh={getExercises} exercise={item} key={item.id} />
        ))}
        <TableFooter
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalPages={exercises?.totalPages ?? 0}
          onPageChange={(selected: number) => {
            setPage(selected)
            if (selected === 0) {
              navigate(``)
            } else {
              navigate(`?page=${selected}`)
            }
          }}
        />
      </div>
    </div>
  )
}

export default ExercisePage
