import { API } from 'api/ApiConfig'
import { getApiHelper } from 'api/ApiHelper'
import { ImageAssets } from 'assets/ImageAssets'
import moment from 'moment'
import { useEffect, useState } from 'react'

const Header = () => {
  const [admin, setAdmin] = useState<any>()

  const getAdmin = () => {
    getApiHelper(
      API.getAdminProfile,
      (data) => {
        setAdmin(data)
      },
      (err) => {
        console.log(err)
      }
    )
  }

  useEffect(() => {
    getAdmin()
  }, [])

  return (
    <header className="w-full flex items-center justify-between py-2 px-2 md:px-5 h-[90px]">
      <div>
        <h3 className="font-bold text-[20px]">Admin Panel</h3>
        <p className="text-sm text-black/50">{moment().format('dddd MMM, YYYY')}</p>
      </div>
      <div className="flex items-center gap-2">
        <div>
          <img
            src={admin?.profile_picture ?? ImageAssets.BariLogo}
            className="w-10 h-10 rounded-full"
            alt=""
          />
        </div>
        <div>
          <h3 className="text-[14px] font-semibold">{admin?.fullName}</h3>
          <p className="text-[12px]">{admin?.role ?? 'ADMIN'}</p>
        </div>
      </div>
    </header>
  )
}

export default Header
