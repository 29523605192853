import { useEffect } from 'react'
import Cookies from 'js-cookie'
import { KEYS } from 'constants/KEYS'
import { Outlet, useNavigate } from 'react-router'
import SideBar from 'components/menu/SideBar'
import Header from 'components/header/Header'

const AppLayout = () => {
  const navigate = useNavigate()
  useEffect(() => {
    if (!Cookies.get(KEYS.token)) {
      // go to the login page
      navigate('/login')
    }
  }, [])
  return (
    <div className="flex w-screen overflow-hidden max-h-screen h-screen items-start">
      <SideBar />
      <div className="flex-1 max-h-screen overflow-hidden h-screen bg-[#F4F7FC]">
        <Header />
        <div className="overflow-scroll">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default AppLayout
