import axios from 'axios'
import { useEffect, useState } from 'react'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import { toast } from 'react-toastify'
import { API } from '../../api/ApiConfig'
import { ImageAssets } from 'assets/ImageAssets'
import Cookies from 'js-cookie'
import { KEYS } from 'constants/KEYS'
import { useNavigate } from 'react-router'

const LoginPage = () => {
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const show = () => {
    setVisible(true)
  }
  const hide = () => {
    setVisible(false)
  }

  const handleUsernameInput = (e: any) => {
    setUsername(e.target.value)
  }

  const handlePasswordInput = (e: any) => {
    setPassword(e.target.value)
  }

  const handleEnterPress = (e: any) => {
    if (e.code === 'Enter') {
      login()
    }
  }

  const login = () => {
    if (!username || !password) {
      toast.error('Please enter a valid username and password')
      return
    }
    axios
      .post(API.login, { username, password })
      .then(({ data }: any) => {
        console.log(data)
        if (!data.success) {
          toast.error(data.message)
        } else {
          // login the user to the platform
          Cookies.set(KEYS.token, data.data.token)
          navigate('/')
        }
      })
      .catch((err) => {
        toast.error('Invalid credentials')
        console.log(err)
      })
  }

  useEffect(() => {
    if (Cookies.get(KEYS.token)) {
      navigate('/')
    }
  }, [])

  return (
    <div className="flex items-center h-screen w-screen justify-center">
      <div className="w-[350px] bg-white p-10 rounded-lg drop-shadow-[2px_2px_10px_#8080804A] ">
        <div className="flex flex-row items-center gap-5 justify-center">
          <img src={ImageAssets.BariLogo} className="rounded-full w-14 h-14" alt="" />
          <h1 className="text-lg font-bold">BariLifestyle</h1>
        </div>
        <div className="mt-5">
          <h1 className="text-[25px] font-bold">Admin Panel</h1>
          <p className="text-sm">Please enter your credentials to login to the admin panel</p>
        </div>
        <div className="mt-10">
          <p className="text-xs font-bold">Username</p>
          <input
            value={username}
            onKeyDown={handleEnterPress}
            onChange={handleUsernameInput}
            type="text"
            placeholder="Enter username"
            className="w-full text-[14px] p-y mt-2 border-b border-black text-black outline-none"
          />
          <p className="text-xs font-bold mt-5">Password</p>
          <div className="flex w-full items-center border-b border-black py-2 mt-2 gap-2">
            <input
              value={password}
              onKeyDown={handleEnterPress}
              onChange={handlePasswordInput}
              type={visible ? 'text' : 'password'}
              placeholder="******"
              className="flex-1 text-[14px] text-black outline-none"
            />
            {!visible ? (
              <AiFillEye onClick={show} cursor="pointer" size={20} />
            ) : (
              <AiFillEyeInvisible onClick={hide} cursor="pointer" size={20} />
            )}
          </div>
          <button
            onClick={login}
            style={{
              background:
                'linear-gradient(90.5deg, #5BC0A1 -21.2%, #94CA65 52.32%, #B1D147 133.52%)'
            }}
            className="mt-10 w-full h-[40px] text-offwhite font-bold text-sm rounded-xl hover:scale-[1.02]">
            Login
          </button>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
