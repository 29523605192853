import { API } from 'api/ApiConfig'
import { getApiHelper } from 'api/ApiHelper'
import UserItem from 'pages/users/components/UserItem'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const LatestUsers = () => {
  const [users, setUsers] = useState([])
  const getUsers = () => {
    getApiHelper(
      `${API.getUsers}?page=0&size=10&sortBy=createdAt&sortDirection=DESC`,
      (data) => {
        setUsers(data?.content ?? [])
      },
      (err) => {
        console.log(err)
      }
    )
  }

  useEffect(() => {
    getUsers()
  }, [])

  return (
    <div
      className="flex-1 rounded-lg p-5"
      style={{
        boxShadow: '0px 0px 100px #8080804D'
      }}>
      <div className="flex mb-[10px] w-full flex-row justify-between items-center">
        <div>
          <h1 className="text-[20px]">Users</h1>
          <p className="text-sm">Latest users</p>
        </div>
        <Link to={'/users/'}>
          <p className="text-sm text-[#94CA65]">View All</p>
        </Link>
      </div>
      <div className="flex font-bold items-center px-2 gap-[10px] mt-5 py-3 bg-[#8080801A]">
        <h4 className="w-[12%] text-sm">Full Name</h4>
        <h4 className="w-[12%] text-sm">Username</h4>
        <h4 className="w-[22%] text-sm">Email</h4>
        <h4 className="w-[10%] text-sm">Role</h4>
        <h4 className="w-[8%] text-sm">Device</h4>
        <h4 className="w-[12%] text-sm">Created At</h4>
        <h4 className="w-[12%] text-sm">Last Active</h4>
        <h4 className="w-[12%] text-sm">Actions</h4>
      </div>
      {users?.map((item: any) => (
        <UserItem refresh={getUsers} user={item} key={item?.id} />
      ))}
    </div>
  )
}

export default LatestUsers
