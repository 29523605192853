import { useMemo } from 'react'

interface TypeProps {
  type: string
}
const ReportType = ({ type }: TypeProps) => {
  const color = useMemo(() => {
    if (!type || type === 'User') {
      return 'bg-[#94CA65]'
    }
    return 'bg-[#FFD700]'
  }, [type])
  return (
    <div className="w-[10%]">
      <p className={`rounded-2xl ${color} text-center px-2 py-1 w-fit text-xs text-white`}>
        {type ?? 'User'}
      </p>
    </div>
  )
}

export default ReportType
