import { API } from 'api/ApiConfig'
import { postApiHelper } from 'api/ApiHelper'
import InnerPageHeader from 'components/header/InnerPageHeader'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

const AddNotificationsPage = () => {
  const navigate = useNavigate()
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  const handleTitleInput = (e: any) => {
    setTitle(e.target.value)
  }

  const handleDescriptionInput = (e: any) => {
    setDescription(e.target.value)
  }

  const saveNotification = () => {
    if (!title || !description) {
      toast.error('Please fill all the fields')
      return
    }
    postApiHelper(
      API.addNotification,
      { title, description },
      () => {
        navigate(-1)
        toast('Notification added successfully')
      },
      (err) => {
        toast.error(err.toString())
      }
    )
  }

  return (
    <div className="px-5">
      <InnerPageHeader hasAction action={saveNotification} title="Add Notification" />
      <p className="text-sm">Add a new notification that can be sent to all users</p>
      <div className="mt-10"></div>
      <div>
        <p className="text-xs font-bold">Title</p>
        <input
          value={title}
          onChange={handleTitleInput}
          className="text-sm mt-1 w-full h-[45px] outline-none bg-[#8080801A] p-1"
          type="text"
          placeholder="Notification title"
        />
        <div className="mt-10"></div>
        <p className="text-xs font-bold">Description</p>
        <textarea
          value={description}
          onChange={handleDescriptionInput}
          className="mt-1 text-sm p-1 w-full min-h-[100px] outline-none bg-[#8080801A]"
          name=""
          placeholder="Notification description"
          id=""></textarea>
      </div>
    </div>
  )
}

export default AddNotificationsPage
