import { API } from 'api/ApiConfig'
import { getApiHelper, postApiHelper, uploadImageApiHelper } from 'api/ApiHelper'
import InnerPageHeader from 'components/header/InnerPageHeader'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

const EditExercisePage = () => {
  const navigate = useNavigate()
  const fileRef = useRef<any>(null)
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState<any>()
  const [exercise, setExercise] = useState<any>({ name: '', description: '', image: '' })

  const setExerciseInfo = (key: string, value: string) => {
    setExercise((prev: any) => {
      const newObj: any = {}
      newObj[key] = value
      const newVal = Object.assign({}, prev, newObj)
      return newVal
    })
  }

  const handleFileChange = (e: any) => {
    setImage(e.target.files[0])
    setExerciseInfo('image', URL.createObjectURL(e.target.files[0]))
  }

  const saveExercise = async () => {
    if (!exercise.name) {
      toast.error('Please enter an exercise name')
      return
    }
    if (!exercise.description) {
      toast.error('Please enter an exercise description')
      return
    }
    let imageURL = exercise.image
    if (image) {
      setLoading(true)
      imageURL = await uploadImageApiHelper(image)
    }
    setLoading(true)
    postApiHelper(
      API.addExercise,
      { ...exercise, image: imageURL },
      () => {
        toast('Exercise Added Successfully')
        navigate(-1)
      },
      (err) => {
        toast.error(err.toString())
      }
    )
  }

  // get the exercise info
  const getExercise = () => {
    getApiHelper(
      `${API.getExercise}${location.pathname.split('/')[3]}`,
      (data) => {
        // console.log(data)
        setExercise(data)
      },
      (err) => {
        toast.error(err.toString())
      }
    )
  }

  useEffect(() => {
    getExercise()
  }, [])

  return (
    <div className="px-5">
      <InnerPageHeader loading={loading} title="Add Exercise" hasAction action={saveExercise} />
      <div className="flex mt-5 gap-[10px] items-end">
        <img
          src={image || exercise.image ? exercise.image : ''}
          className="h-[250px] object-contain w-[250px] bg-[#8080801A]"
          alt=""
        />
        <button
          onClick={() => {
            fileRef.current.click()
          }}
          className="text-white p-2 text-sm rounded-[50px]"
          style={{
            background: 'linear-gradient(90.5deg, #5BC0A1 -21.2%, #94CA65 52.32%, #B1D147 133.52%)'
          }}>
          Update Image
        </button>
        <input
          ref={fileRef}
          className="invisible w-[10px]"
          type="file"
          onChange={handleFileChange}
        />
      </div>
      <p className="mt-5 font-bold text-sm mb-1">Name</p>
      <input
        value={exercise.name}
        onChange={(e) => setExerciseInfo('name', e.target.value)}
        type="text"
        className="bg-[#8080800D] w-full outline-none p-1"
        placeholder="Enter title"
      />
      <p className="mt-5 font-bold text-sm mb-1">Description</p>
      <textarea
        value={exercise.description}
        onChange={(e) => setExerciseInfo('description', e.target.value)}
        className="bg-[#8080800D] w-full text-sm min-h-[250px] outline-none p-1"
        placeholder="Enter Description"
      />
    </div>
  )
}

export default EditExercisePage
