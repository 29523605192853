import { useNavigate } from 'react-router'
import { IoIosArrowBack } from 'react-icons/io'

interface ItemProps {
  title: string
  banned: boolean
  handleReset?: () => any
  handleBlock?: () => any
  handleUnblock?: () => any
  handleDelete?: () => any
  loading?: boolean
  action?: () => void
}
const UserDetailHeader = ({
  banned,
  title,
  loading = false,
  action = () => {},
  handleReset,
  handleBlock,
  handleUnblock,
  handleDelete
}: ItemProps) => {
  const navigate = useNavigate()

  const back = () => {
    navigate(-1)
  }

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-[10px]">
        <IoIosArrowBack cursor="pointer" size={20} onClick={back} />
        <h1 className="font-bold text-[20px]">{title}</h1>
      </div>
      <div className="flex items-center gap-5">
        <button
          disabled={loading}
          onClick={handleDelete}
          className="text-sm text-white h-[40px] rounded-[10px] px-1 min-w-[100px]"
          style={{
            background: loading ? '#808080CC' : 'red'
          }}>
          Delete
        </button>
        <button
          disabled={loading}
          onClick={handleReset}
          className="text-sm text-white h-[40px] rounded-[10px] px-2 min-w-[100px]"
          style={{
            background: loading ? '#808080CC' : 'orange'
          }}>
          Reset Password
        </button>
        {banned ? (
          <button
            disabled={loading}
            onClick={handleUnblock}
            className="text-sm text-white h-[40px] rounded-[10px] px-1 min-w-[100px]"
            style={{
              background: loading ? '#808080CC' : 'green'
            }}>
            Unblock
          </button>
        ) : (
          <button
            disabled={loading}
            onClick={handleBlock}
            className="text-sm text-white h-[40px] rounded-[10px] px-1 min-w-[100px]"
            style={{
              background: loading ? '#808080CC' : 'red'
            }}>
            Block
          </button>
        )}

        <button
          disabled={loading}
          onClick={action}
          className="text-sm text-white h-[40px] rounded-[10px] px-1 min-w-[100px]"
          style={{
            background: loading
              ? '#808080CC'
              : 'linear-gradient(90.5deg, #5BC0A1 -21.2%, #94CA65 52.32%, #B1D147 133.52%)'
          }}>
          Save
        </button>
      </div>
    </div>
  )
}

export default UserDetailHeader
