import ListModal from 'components/modal/ListModal'
import { AnimatePresence } from 'framer-motion'
import { useMemo, useState } from 'react'
import { PiGenderMale, PiGenderFemale } from 'react-icons/pi'

interface ItemProps {
  data: any
}
const Genders = ({ data }: ItemProps) => {
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const malePercent = useMemo(() => {
    if (data) {
      const total = parseInt(data.male) + parseInt(data.female) + parseInt(data.other)
      return parseInt(data.male) / total
    }
    return 1
  }, [data])

  const femalePercent = useMemo(() => {
    if (data) {
      const total = parseInt(data.male) + parseInt(data.female) + parseInt(data.other)
      return parseInt(data.female) / total
    }
    return 1
  }, [data])
  const otherPercent = useMemo(() => {
    if (data) {
      const total = parseInt(data.male) + parseInt(data.female) + parseInt(data.other)
      return parseInt(data.other ?? 0) / total
    }
    return 1
  }, [data])

  const handleShowModal = (value: string) => {
    setShowModal(true)
    setModalTitle(value)
  }

  return (
    <div
      className="flex-1 rounded-lg p-5"
      style={{
        boxShadow: '0px 0px 100px #8080804D'
      }}>
      <AnimatePresence initial={false}>
        {showModal && <ListModal title={modalTitle} handleCloseModal={() => setShowModal(false)} />}
      </AnimatePresence>
      <div className="flex items-center justify-between">
        <div className="cursor-pointer" onClick={() => handleShowModal('male')}>
          <h1 className="text-[25px]">{data?.male}</h1>
          <p>Male</p>
        </div>
        <div className="cursor-pointer" onClick={() => handleShowModal('female')}>
          <h1 className="text-[25px]">{data?.female}</h1>
          <p>Female</p>
        </div>
        <div className="cursor-pointer" onClick={() => handleShowModal('other')}>
          <h1 className="text-[25px]">{data?.other ?? 0}</h1>
          <p>Other</p>
        </div>
        <div className="flex items-center">
          <PiGenderMale size={34} color="#3B81F6" />
          <PiGenderFemale size={34} color="#EC4899" />
        </div>
      </div>

      <div className="flex-1 mt-5">
        <div className="flex items-end gap-[10px]">
          <div className="flex flex-col items-center justify-center">
            <div
              onClick={() => handleShowModal('male')}
              style={{
                height: 70 * malePercent
              }}
              className={`rounded-md hover:opacity-50 cursor-pointer w-[25px] bg-blue-500`}
            />
            <p className="text-sm">Male</p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div
              onClick={() => handleShowModal('female')}
              style={{
                height: 70 * femalePercent
              }}
              className="rounded-md hover:opacity-50 cursor-pointer w-[25px] bg-pink-500"
            />
            <p className="text-sm">Female</p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div
              onClick={() => handleShowModal('other')}
              style={{
                height: 70 * otherPercent
              }}
              className="rounded-md hover:opacity-50 cursor-pointer w-[25px] bg-pink-500"
            />
            <p className="text-sm">Other</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Genders
