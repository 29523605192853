import InnerPageHeader from 'components/header/InnerPageHeader'
import PostItem from './components/PostItem'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getApiHelper } from 'api/ApiHelper'
import { API } from 'api/ApiConfig'
import CommentItem from './components/CommentItem'

const CommunityDetailPage = () => {
  const [post, setPost] = useState<any>(null)
  const [comments, setComments] = useState<any[]>([])

  const getComments = () => {
    const id = location.pathname.split('/')[2]
    getApiHelper(
      `${API.getComments}${id}`,
      (data) => {
        setComments(data)
        console.log(data)
      },
      (err) => {
        toast.error(err?.toString())
      }
    ).catch((err: any) => {
      console.log(err)
    })
  }

  const getPost = () => {
    const id = location.pathname.split('/')[2]
    getApiHelper(
      `${API.getPost}${id}`,
      (data) => {
        setPost(data)
        getComments()
      },
      (err) => {
        console.log(err)
        toast.error(err?.toString())
      }
    ).catch((err: any) => {
      console.log(err)
    })
  }
  useEffect(() => {
    getPost()
  }, [])
  return (
    <div className="px-5">
      <InnerPageHeader title="Community Post" hasAction={false} />
      {post !== null && (
        <div>
          <PostItem refresh={() => {}} post={post} />
          <h4 className="my-3 font-bold">Comments</h4>
          {comments.map((item) => (
            <CommentItem refresh={getComments} comment={item} key={item?.id} />
          ))}
        </div>
      )}
    </div>
  )
}

export default CommunityDetailPage
