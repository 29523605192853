import { API } from 'api/ApiConfig'
import { getApiHelper, postApiHelper } from 'api/ApiHelper'
import InnerPageHeader from 'components/header/InnerPageHeader'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

const EditNotificationsPage = () => {
  const navigate = useNavigate()
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [id, setId] = useState('')

  const handleTitleInput = (e: any) => {
    setTitle(e.target.value)
  }

  const handleDescriptionInput = (e: any) => {
    setDescription(e.target.value)
  }

  useEffect(() => {
    getApiHelper(
      `${API.getNotification}${location.pathname.split('/')[3]}`,
      (data) => {
        setTitle(data.title)
        setDescription(data.description)
        setId(data.id)
      },
      (err) => {
        toast.error(err)
      }
    ).catch((err) => {
      console.log(err)
    })
  }, [])

  const saveNotification = () => {
    if (!title || !description) {
      toast.error('Please fill all the fields')
      return
    }
    postApiHelper(
      `${API.getNotification}${location.pathname.split('/')[3]}`,
      { title, description, id },
      () => {
        navigate(-1)
        toast('Notification updated successfully')
      },
      (err) => {
        toast.error(err.toString())
      }
    )
  }

  return (
    <div className="px-5">
      <InnerPageHeader hasAction action={saveNotification} title="Edit Notification" />
      <p className="text-sm">Edit the notification</p>
      <div className="mt-10"></div>
      <div>
        <p className="text-xs font-bold">Title</p>
        <input
          value={title}
          onChange={handleTitleInput}
          className="text-sm mt-1 w-full h-[45px] outline-none bg-[#8080801A] p-1"
          type="text"
          placeholder="Notification title"
        />
        <div className="mt-10"></div>
        <p className="text-xs font-bold">Description</p>
        <textarea
          value={description}
          onChange={handleDescriptionInput}
          className="mt-1 text-sm p-1 w-full min-h-[100px] outline-none bg-[#8080801A]"
          name=""
          placeholder="Notification description"
          id=""></textarea>
      </div>
    </div>
  )
}

export default EditNotificationsPage
